import React, { useState, useEffect } from 'react';
import NavBar from '../../components/navbar/navBarComponent';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import NewsFeed from '../../components/news-feed/newsFeed';
import newsUtils from '../../utils/news';
import Search from '../../components/search/searchComponent';
import PageControls from '../../components/table-pagination/pageControls';
import config from '../../config/tableConfig';

const PAGE_SIZE = 10;

const NewsFeedAdminPage = (props) => {
  const [news, setNews] = useState(null);
  const [newsVisible, setNewsVisible] = useState(null);
  const [page, setPageNumber] = useState(1);
  const [isFiltered, setIsFiltered] = useState(null);
  const [searchStr, setSearchStr] = useState(null);

  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'News Feed', link: '/news-feed' },
    { name: 'News Feed Admin', link: '/news-feed-admin' },
  ];

  useEffect(() => {
    async function getNews() {
      const news = await newsUtils.getAllNews();
      return news;
    }
    getNews().then((res) => {
      if (res.success) {
        setNews(res.data);
        const firstPageNews = res.data.slice(0, PAGE_SIZE);
        setNewsVisible(firstPageNews);
      }
    });
  }, []);

  const generateNewsList = (newsListEnabled) => {
    const newsList = [];
    if (newsListEnabled) {
      for (let i = 0; i < newsListEnabled.length; i++) {
        newsList.push(
          <NewsFeed
            key={newsListEnabled[i]._id}
            newsData={newsListEnabled[i]}
          />
        );
      }
    }
    return newsList;
  };

  const navigateNextPage = () => {
    const maxPage = Math.ceil(news.length / PAGE_SIZE);
    const nextPage = page + 1;
    if (nextPage <= maxPage) {
      const nextVisibleItems = news.slice(
        page * PAGE_SIZE,
        nextPage * PAGE_SIZE
      );
      setNewsVisible(nextVisibleItems);
      setPageNumber(nextPage);
    }
  };

  const navigatePrevPage = () => {
    const prevPage = page - 1;
    if (prevPage >= 1) {
      const previousVisibleItems = news.slice(
        (prevPage - 1) * PAGE_SIZE,
        prevPage * PAGE_SIZE
      );
      setNewsVisible(previousVisibleItems);
      setPageNumber(prevPage);
    }
  };

  const filterNews = (e) => {
    const filterStr = e.target.value.toLowerCase();
    setSearchStr(filterStr);
    const words = filterStr.split(' ');

    if (filterStr.length > 0) {
      let filteredNews = news;
      for (let i = 0; i < words.length; i++) {
        filteredNews = news.filter((el) => {
          return (
            el.title.toString().toLowerCase().includes(words[i]) ||
            el.body.toString().toLowerCase().includes(words[i]) ||
            new Date(el.date)
              .toLocaleDateString(config.locales.region)
              .includes(words[i])
          );
        });
      }
      setNewsVisible(filteredNews);
      setIsFiltered(true);
    } else {
      setNewsVisible(news);
      setIsFiltered(false);
    }
  };

  return (
    <div id="main-news-feed" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page="news-feed-admin" items={breadcrumbItems} />
      <Search
        text="Buscar"
        placeholder="Buscar noticia por Titulo o Fecha"
        searchCallback={filterNews}
        value={searchStr}
      />
      {generateNewsList(newsVisible)}
      <PageControls
        isFiltered={isFiltered}
        currentPage={page}
        nextPageHandler={navigateNextPage}
        prevPageHandler={navigatePrevPage}
        totalPages={news ? Math.ceil(news.length / PAGE_SIZE) : 0}
      />
    </div>
  );
};

export default NewsFeedAdminPage;
