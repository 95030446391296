import React, { useCallback, useEffect, useState } from 'react';
import './menuComponent.css';
import './menuItem'
import MenuItem from './menuItem';
import menuConfig from '../../config/menuConfig';
import NavBar from '../navbar';
import Title from "../title-tile/titleTileComponent";
import productUtils from '../../utils/product/product-utils';
import newsUtils from '../../utils/news';
import defaultLogo from '../../assets/img/default-logo-new.png';

const counterCart = 'cart';
const counterNews = 'news';

const Menu = (props) => {
  const [logo, setLogo] = useState(defaultLogo);
  const [newsEnabled, setNewsEnabled] = useState([]);

  useEffect(() => {
    async function getBusinessData() {
      const config = await productUtils.getBusinessConfig();
      return config;
    }
    getBusinessData().then(config => {
      if (config.success && config.data.logoURL) {
        setLogo(config.data.logoURL);
      }
    });

    // We get the enabled news to see which ones are not read
    async function getAllEnabledNews() {
      const news = await newsUtils.getEnabledNews();
      return news;
    }
    getAllEnabledNews().then(news => {
      if (news.success && news.data) {
        setNewsEnabled(news.data);
      }
    });
  }, []);

  const showBadge = (item) => {
    return props.cart.getItems().length > 0 || item.admin;
  };

  const calculateNewsNumber = (allEnabled, read) => {
    let newsToRead = 0;
    for (let i = 0; i < allEnabled.length; i++) {
      if (!read.includes(allEnabled[i]._id)) {
        newsToRead += 1;
      }
    }
    return newsToRead;
  }

  const getTextCounters = useCallback((item) => {
    let count = 0;
    switch (item.counter) {
      case counterCart:
        count = props.cart.getItems().length;
        break;
      case counterNews:
        count = calculateNewsNumber(newsEnabled, props.news.getNewsReadByUser());
        break;
      default:
        count = 0;
    }
    return count;
  }, [newsEnabled, props.cart, props.news])

  const generateMenu = () => {
    const items = [];
    for (let i = 0; i < menuConfig.length; i++) {
      // Should be admin to show the admin routes
      if ((menuConfig[i].admin && props.user.tags.includes('admin')) || !menuConfig[i].admin) {
        items.push(
          <MenuItem
            key={`${menuConfig[i].title}-${i}`}
            title={menuConfig[i].title}
            text={menuConfig[i].text}
            link={menuConfig[i].link}
            icon={menuConfig[i].icon}
            badge={menuConfig[i].badge || (menuConfig[i].badge && showBadge(menuConfig[i]))}
            badgeText={menuConfig[i].badgeText ? menuConfig[i].badgeText : getTextCounters(menuConfig[i])}
            altText={showBadge(menuConfig[i]) ? menuConfig[i].altText : ''}
          />
        )
      }
    }
    return items;
  };

  return (
    <div>
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <div id='main-menu' className="container-fluid">
        <Title
          message={`Bienvenido ${props.user.fullName}!`}
          hide={false}
        />
        <img alt="logo-company" className="center-logo" src={logo} />
        <div className="menu-container">
          {generateMenu()}
        </div>
      </div>
    </div>
  );
};

export default Menu;