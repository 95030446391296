import React, { useState, useEffect } from 'react';
import './productTableComponent.css';
import productUtils from '../../utils/product/product-utils';
import config from '../../config/tableConfig';
import Loader from '../loader/loaderComponent';
import Search from '../search/searchComponent';
import SearchBrand from '../search/brandSearchImage';
import PageControls from '../table-pagination/pageControls';
import Title from '../title-tile/titleTileComponent';
import SearchDropdown from '../search/searchDropdownComponent';

const PAGE_SIZE = config.pageSize;

const ProductTable = (props) => {
  const [products, setProducts] = useState(null);
  const [visibleProducts, setVisibleProducts] = useState(null);
  const [brandFilteredProducts, setBrandFilteredProducts] = useState(null);
  const [page, setPageNumber] = useState(1);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchBrand, setSearchBrand] = useState(null);
  const [searchProduct, setSearchProduct] = useState(null);
  const [searchVisible, setSearchVisible] = useState(true);

  useEffect(() => {
    async function getBusinessData() {
      const config = await productUtils.getBusinessConfig();
      return config;
    }
    getBusinessData().then(async (businessConfig) => {
      if (businessConfig.success) {
        const csvProducts = await productUtils.getCSV(
          businessConfig.data.productsURL
        );
        const JSONProducts = productUtils.csvToJSON(csvProducts);
        const firstPageProducts = JSONProducts.slice(0, PAGE_SIZE);
        setProducts(JSONProducts);
        setVisibleProducts(firstPageProducts);
        setIsLoaded(true);
      }
    });
  }, []);

  const filterTable = (allProducts) => {
    let targetSearch = products;
    if (isFiltered && allProducts !== true) {
      targetSearch = visibleProducts;
    }
    if (
      isFiltered &&
      allProducts === true &&
      searchProduct &&
      brandFilteredProducts
    ) {
      targetSearch = brandFilteredProducts;
    }
    if (searchProduct && searchProduct.length > 0) {
      const words = searchProduct.split(' ');
      let filteredPartialResults = targetSearch;
      for (let i = 0; i < words.length; i++) {
        filteredPartialResults = filteredPartialResults.filter((el) => {
          let results = [];
          // Search by product description or ID
          const element =
            el[config.searchCriteria.product] &&
            el[config.searchCriteria.product].toLowerCase();
          const id = el[config.keyColumn];
          if (element && id) {
            results =
              element.includes(words[i].toLowerCase()) ||
              id.includes(words[i].toLowerCase());
          }
          return results;
        });
      }
      setVisibleProducts(filteredPartialResults);
      setIsFiltered(true);
    } else {
      if (brandFilteredProducts) {
        setVisibleProducts(brandFilteredProducts);
      } else {
        setVisibleProducts(products.slice(0, PAGE_SIZE));
        setIsFiltered(false);
      }
    }
  };

  const filterBrand = (allProducts, brand) => {
    let targetSearch = products;
    if (isFiltered && allProducts !== true) {
      targetSearch = visibleProducts;
    }

    const search = brand || searchBrand;
    if (search && search.length > 0) {
      const filteredResults = targetSearch.filter((el) => {
        return (
          el[config.searchCriteria.brand] &&
          el[config.searchCriteria.brand]
            .toLowerCase()
            .includes(search.toLowerCase())
        );
      });
      setVisibleProducts(filteredResults);
      setBrandFilteredProducts(filteredResults);
      setIsFiltered(true);
    } else {
      setBrandFilteredProducts(null);
      setVisibleProducts(products.slice(0, PAGE_SIZE));
      setIsFiltered(false);
    }
  };

  const inputSearchBrand = (e) => {
    const brandStr = e.target.value;
    setSearchBrand(brandStr);
    if (!brandStr) {
      filterTable(true);
    }
  };

  const inputSearchProduct = (e) => {
    const productStr = e.target.value;
    setSearchProduct(productStr);
    if (!productStr) {
      filterBrand(true);
    }
  };

  const getProductById = (id) => {
    return products.find((elem) => elem[config.keyColumn] === id);
  };

  const navigateNextPage = () => {
    const maxPage = Math.ceil(products.length / PAGE_SIZE);
    const nextPage = page + 1;
    if (nextPage <= maxPage) {
      const nextVisibleItems = products.slice(
        page * PAGE_SIZE,
        nextPage * PAGE_SIZE
      );
      setVisibleProducts(nextVisibleItems);
      setPageNumber(nextPage);
    }
  };

  const navigatePrevPage = () => {
    const prevPage = page - 1;
    if (prevPage >= 1) {
      const previousVisibleItems = products.slice(
        (prevPage - 1) * PAGE_SIZE,
        prevPage * PAGE_SIZE
      );
      setVisibleProducts(previousVisibleItems);
      setPageNumber(prevPage);
    }
  };

  const addProductToCart = (id) => {
    // search product on the products state
    const product = getProductById(id);
    const productStock = parseInt(product[config.stockColumn], 10);

    // show the cart add and quantity
    const messageItem = `${product.Producto} - ${product.Marca} - ${product['Tamaño']}`;
    const quantity = prompt(`¿Cuantas unidades de ${messageItem} desea?`, '1');

    // Check if user is admin to buy with no stock checks
    const isAdmin = props.user && props.user.tags.includes('admin');

    // If the user selects some quantity
    if (quantity) {
      const hasStock = productStock - parseInt(quantity, 10) >= 0;

      // Add check if already in the cart to update the quantity
      const currentCart = props.cart;
      const alreadyInCart = currentCart
        .getItems()
        .filter((item) => item.product[config.keyColumn] === id);
      if (alreadyInCart.length > 0) {
        const replaceQuantity = window.confirm(
          'El producto ya esta en el carrito. ¿Desea actualizar la cantidad?'
        );
        if (replaceQuantity) {
          currentCart.updateItemQuantity(id, quantity);
        }
        return;
      }

      // Add to the cart if has stock or if is Admin
      if ((parseInt(quantity, 10) > 0 && hasStock) || isAdmin) {
        if (!hasStock && isAdmin) {
          alert(
            `ATENCION: Esta agregando un producto ${messageItem} sin stock. Puede continuar porque es ADMIN.`
          );
        }
        const subtotal = parseFloat(product[config.priceColumn]) * quantity;
        currentCart.addItem({
          product,
          Cantidad: quantity,
          Subtotal: subtotal,
        });
        props.cartCount(currentCart.getItems().length);
      } else if (!hasStock && config.stockCheck) {
        alert(`No hay stock del producto ${messageItem}`);
      } else {
        alert(`La cantidad ingresada no es válida.`);
      }
    }
  };

  const generateTable = (products) => {
    const headers = products ? Object.keys(products[0]) : null;

    const generateHeaders = (headers) => {
      const tableHeaders = [];
      if (headers) {
        for (let i = 0; i < headers.length; i++) {
          tableHeaders.push(
            <th
              className={
                config.noMobile[headers[i]]
                  ? 'text-center mobile-hidden'
                  : 'text-center'
              }
              key={`col-he-${i}`}
              scope="col"
            >
              {headers[i]}
            </th>
          );
        }
      }
      return tableHeaders;
    };

    const generateRowData = (product) => {
      const data = [];
      if (product[config.keyColumn]) {
        const keys = Object.keys(product);
        keys.forEach((key) =>
          data.push(
            <td
              style={
                config.style[key] ? { textAlign: config.style[key] } : null
              }
              className={config.noMobile[key] ? 'mobile-hidden' : null}
              key={`${product[key]}`}
            >
              {productUtils.formatData(product[key], key)}
            </td>
          )
        );
      }
      return data;
    };

    const generateRows = (products) => {
      const productItems = [];
      if (products) {
        for (let i = 0; i < products.length; i++) {
          productItems.push(
            <tr
              className="row-clickeable"
              key={`row-${i}`}
              onClick={() => addProductToCart(products[i][config.keyColumn])}
            >
              {generateRowData(products[i])}
            </tr>
          );
        }
      }
      return productItems;
    };

    return (
      <>
        <table className="table table-sm table-responsive table-hover table-bordered table-custom">
          <thead className="thead-light">
            <tr>{generateHeaders(headers)}</tr>
          </thead>
          <tbody>{generateRows(visibleProducts)}</tbody>
        </table>
        {visibleProducts && visibleProducts.length === 0 && (
          <Title
            message="No se encontraron resultados para la busqueda."
            hide={false}
          />
        )}
      </>
    );
  };

  const getUniqueBrands = (list) => {
    let brands = [];
    if (list) {
      brands = list
        .map((item) => item['Marca'])
        .filter((value, index, self) => self.indexOf(value) === index);
    }
    return brands.sort();
  };

  const setSearchStatus = () => {
    setSearchVisible(!searchVisible);
  };

  const resetSearchStatus = () => {
    setSearchBrand(null);
    setSearchProduct('');
    setBrandFilteredProducts(null);
    setVisibleProducts(products.slice(0, PAGE_SIZE));
    setIsFiltered(false);
  };

  return (
    <div>
      <Loader show={!isLoaded} />
      <div style={{ display: !isLoaded ? 'none' : 'block' }}>
        <div className="card mt-3 mb-3 ml-0 mr-0">
          <div
            className="card-header pt-1 pb-2 pl-0 search-header"
            id="headingOne"
          >
            <h5 className="mb-0">
              <button
                className="btn search-header-button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                onClick={setSearchStatus}
              >
                <i
                  className="material-icons icon-action icon-check collapsible-icon"
                  style={{ display: searchVisible ? 'none' : 'block' }}
                >
                  expand_less
                </i>
                <i
                  className="material-icons icon-action icon-check collapsible-icon"
                  style={{ display: searchVisible ? 'block' : 'none' }}
                >
                  expand_more
                </i>
                <i className="material-icons icon-action icon-check">search</i>
                Busqueda por Marcas y Productos
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            className={searchVisible ? 'collapse show' : 'collapse'}
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div className="card-body p-0">
              <SearchBrand
                brands={getUniqueBrands(products)}
                buttonCallback={filterBrand}
              />
              <SearchDropdown
                text="Marca"
                includeButton={true}
                searchCallback={inputSearchBrand}
                buttonCallback={filterBrand}
                setSearchValueCallback={setSearchBrand}
                value={searchBrand}
                dropdownValues={getUniqueBrands(products)}
              />
              <Search
                text="Producto"
                includeButton={true}
                searchCallback={inputSearchProduct}
                buttonCallback={filterTable}
                value={searchProduct}
              />
              {isFiltered && (
                <div style={{ display: 'flex', flex: 'auto' }}>
                  <button
                    className="btn btn-dark btn-cart ml-2 mr-2 mb-2"
                    onClick={resetSearchStatus}
                  >
                    <span className="badge badge-pill badge-margin badge-danger mr-1">
                      {'X'}
                    </span>
                    Limpiar Busqueda
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {generateTable(products)}
        <PageControls
          isFiltered={isFiltered}
          currentPage={page}
          nextPageHandler={navigateNextPage}
          prevPageHandler={navigatePrevPage}
          totalPages={products ? Math.ceil(products.length / PAGE_SIZE) : 0}
        />
      </div>
    </div>
  );
};

export default ProductTable;
