export default [
  {
    title: 'Pedido',
    text: 'Nuevo Pedido',
    link: '/order',
    icon: 'add_shopping_cart',
    badge: true,
    altText: 'Continuar Pedido',
    counter: 'cart',
  },
  {
    title: 'Historico',
    text: 'Historico de Pedidos',
    link: '/history',
    icon: 'history',
  },
  {
    title: 'Precios',
    text: 'Lista de Precios',
    link: 'https://docs.google.com/spreadsheets/d/1zV78BSBK8EC5RVNu8WqXjQ3-_1VczmZ9qZ7QVSXrYaE/export?format=xlsx',
    icon: 'monetization_on',
  },
  {
    title: 'Ofertas',
    text: 'Nuevas Ofertas',
    link: '/promo',
    icon: 'redeem',
    badge: true,
    badgeText: 'NUEVO!',
  },
  {
    title: 'Cuenta Corriente',
    text: 'Saldo Cuenta',
    link: '/account',
    icon: 'account_balance',
  },
  {
    title: 'Noticias',
    text: 'Informacion Importante',
    link: '/news',
    icon: 'feed',
    badge: true,
    counter: 'news',
  },
  {
    title: 'Consultas',
    text: 'Envio de Consultas',
    link: '/questions',
    icon: 'contact_support',
  },
  {
    title: 'Cupones Tarjeta',
    text: 'Carga Comprobantes',
    link: '/receipts',
    icon: 'assignment_return',
  },
  {
    title: 'Usuarios',
    text: 'Gestion Usuarios',
    link: '/users',
    icon: 'group',
    badge: true,
    badgeText: 'ADMIN',
    admin: true,
  },
  {
    title: 'Ordenes',
    text: 'Gestion Pedidos',
    link: '/admin',
    icon: 'store',
    badge: true,
    badgeText: 'ADMIN',
    admin: true,
  },
  {
    title: 'Feed Noticias',
    text: 'Gestion Noticias',
    link: '/news-feed',
    icon: 'note_add',
    badge: true,
    badgeText: 'ADMIN',
    admin: true,
  },
];
