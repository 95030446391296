import React from 'react';

const DeliveryComments = (props) => {
  const setComments = (e) => {
    const comments = e.target.value;
    // Just 500 chars of comments allowed
    if (comments.length < 500) {
      props.setDeliveryComments(comments);
    }
  };

  return (
    <>
      <p className="instructions-text">
        Ingrese si desea comentarios sobre el pedido:
      </p>
      <div className="input-group col-12 col-md-4">
        <div className="input-group-prepend">
          <span className="input-group-text">Observaciones</span>
        </div>
        <textarea
          onChange={setComments}
          className="form-control"
          aria-label="Observaciones"
        ></textarea>
      </div>
    </>
  );
};

export default DeliveryComments;
