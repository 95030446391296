import React, {useState} from 'react';
import cartUtils from '../../utils/cart/cartComponent';

const InputQuantity = props => {
    const [quantity, setQuantity] =  useState(parseInt(props.initialValue));

    const updateQuantity = (event, id) => {
        const newQuantity = parseInt(event.target.value);
        // We check the stock value here too
        if (newQuantity > 0 && newQuantity <= props.productStock && !props.isPromoItem) {
            setQuantity(newQuantity);
            cartUtils.updateItemQuantity(id, newQuantity);
            props.setCartItemsCallback(cartUtils.getItems());
        }
    };

    return (
        <input type="number" style={{ maxWidth: '60px'}}
               value={quantity}
               onChange={(event) => updateQuantity(event, props.productId)}
               required
        />
    )
};

export default InputQuantity;