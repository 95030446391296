import config from '../../config/tableConfig';

// Define a version and key to have
// background compatibility on new changes
const getCartKey = () => {
  const cartVersion = '1';
  const customer = localStorage.getItem('customer');
  const cartKey = `cart-${cartVersion}-${customer}`;
  return cartKey;
};

const addItem = (item) => {
  const cartKey = getCartKey();
  const items = JSON.parse(localStorage.getItem(cartKey));
  if (items) {
    items.push(item);
    localStorage.setItem(cartKey, JSON.stringify(items));
  } else {
    localStorage.setItem(cartKey, JSON.stringify([]));
    const items = JSON.parse(localStorage.getItem(cartKey));
    items.push(item);
    localStorage.setItem(cartKey, JSON.stringify(items));
  }
};

const getItems = () => {
  const cartKey = getCartKey();
  return JSON.parse(localStorage.getItem(cartKey)) || [];
};

const updateItemQuantity = (id, newQuantity) => {
  const cartKey = getCartKey();
  const items = JSON.parse(localStorage.getItem(cartKey));
  const modifiedItems = items.map((item) => {
    if (item.product[config.keyColumn] === id) {
      item['Cantidad'] = newQuantity;
      item['Subtotal'] =
        parseFloat(item.product[config.priceColumn]) * newQuantity;
    }
    return item;
  });
  localStorage.setItem(cartKey, JSON.stringify(modifiedItems));
};

const clearCart = () => {
  const cartKey = getCartKey();
  localStorage.setItem(cartKey, JSON.stringify([]));
};

const deleteItem = (id) => {
  const cartKey = getCartKey();
  let items = JSON.parse(localStorage.getItem(cartKey));
  items = items.filter((item) => item.product[config.keyColumn] !== id);
  localStorage.setItem(cartKey, JSON.stringify(items));
};

export default {
  addItem,
  getItems,
  clearCart,
  deleteItem,
  updateItemQuantity,
};
