import React from 'react';

const Search = (props) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // If has button we execute the button action
      if (props.includeButton) {
        props.buttonCallback();
      } else {
        props.searchCallback(e);
      }
    }
    if (e.keyCode === 8) {
      if (props.includeButton) {
        props.buttonCallback(true);
      }
    }
  };

  return (
    <div className="input-group m-2" style={{ width: 'auto' }}>
      <div className="input-group-prepend">
        <span
          className="input-group-text search-input"
          id="search-text"
          style={{ minWidth: '120px', maxWidth: '120px' }}
        >
          {props.text || 'Buscar'}
        </span>
      </div>
      <input
        onChange={props.searchCallback}
        onKeyDown={handleKeyDown}
        type="text"
        className="form-control search-input"
        aria-describedby="search-text"
        placeholder={props.placeholder}
        value={props.value || ''}
      ></input>
      {props.includeButton && (
        <div className="input-group-append">
          {props.clearCallback && (
            <button
              onClick={() => props.clearCallback('')}
              className="btn btn-outline-secondary"
              type="button"
            >
              <i className="material-icons icon-action icon-delete">
                backspace
              </i>
            </button>
          )}
          <button
            onClick={props.buttonCallback}
            className="btn btn-outline-secondary"
            type="button"
          >
            Buscar
          </button>
        </div>
      )}
    </div>
  );
};

export default Search;
