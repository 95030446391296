import axios from 'axios';
import config from '../../config/main';

const getUserInfo = async () => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${config.apiEndpoint}/auth`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

const authenticate = async (formData) => {
  try {
    const user = await axios({
      method: 'POST',
      url: `${config.apiEndpoint}/login`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return user.data;
  } catch (err) {
    throw new Error(err);
  }
};

const logout = () => {
  try {
    fetch(`${config.apiEndpoint}/logout`).then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    });
  } catch (err) {
    throw new Error(err);
  }
};

const signup = async (formData) => {
  try {
    const user = await axios({
      method: 'POST',
      url: `${config.apiEndpoint}/users`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return user.data;
  } catch (err) {
    throw new Error(err);
  }
};

export default {
  authenticate,
  getUserInfo,
  logout,
  signup,
};
