import React from 'react';
import './slider.css';

const Slider = (props) => {
  return (
    <label className="label-sliders">
      {props.title}
      <label className="switch">
        <input type="checkbox" checked={props.checked} onChange={props.setChecked} />
        <span className="slider round"></span>
      </label>
    </label>
  );
}

export default Slider;
