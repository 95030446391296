import React, { useEffect, useState } from 'react';
import auth from '../../utils/auth-provider/authProvider';
import { Redirect, Route } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...rest }) => {
  const [user, setUser] = useState({ success: false });
  const [order, setOrder] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [needsLogin, setNeedsLogin] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await auth.getUserInfo();
        setUser(data);
        setLoaded(true);
        // Redirect to login in failure
        if (!data.success) {
          setNeedsLogin(true);
        }
      } catch (err) {
        console.error(
          `Error trying to fetch user data: ${JSON.stringify(err.message)}`
        );
      }
    }
    fetchData();
  }, []);

  const renderComponent = () => {
    if (!loaded) return null;
    if (needsLogin) return <Redirect to="/login"></Redirect>;
    return (
      <Route
        {...rest}
        render={(props) =>
          user.success &&
          user.user.active &&
          user.user.tags.includes('admin') ? (
            <Component
              {...props}
              isLogIn={user.success}
              user={{ ...user.user }}
              cart={rest.cart}
              setOrder={setOrder}
              order={order}
            />
          ) : null
        }
      />
    );
  };

  return <>{renderComponent()}</>;
};

export default AdminRoute;
