import React from 'react';
import NavBar from '../../components/navbar';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import AccountInfo from '../../components/account-info/accountInfo';

const AccountPage = (props) => {
  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'Cuenta Corriente', link: '/account' },
  ];

  return (
    <div id="main-account" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page="account" items={breadcrumbItems} />
      <h3>Cuenta Corriente Numero: {props.user.internalId}</h3>
      <AccountInfo user={props.user}></AccountInfo>
      <a className="btn btn-dark btn-cart" href="/menu" role="button">
        Volver al menu
      </a>
    </div>
  );
};

export default AccountPage;
