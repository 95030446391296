import React, { useState } from 'react';
import StatusBadge from '../status-badge/statusBadge';
import utils from '../../utils/product/product-utils';
import newsUtils from '../../utils/news';
import Slider from '../slider/slider';

const NewsFeed = (props) => {
  const [enabled, setIsEnabled] = useState(props.newsData.enabled);
  const [title, setTitle] = useState(props.newsData.title)
  const [errorTitle, setErrorTitle] = useState(null)
  const [body, setBody] = useState(props.newsData.body)
  const [errorBody, setErrorBody] = useState(null)
  const [pictureURL, setPictureURL] = useState(props.newsData.pictureURL)
  const [errorPictureURL, setErrorPictureURL] = useState(null)
  const [error, setError] = useState(null);

  const getStatusBadge = () => {
    const badges = [];
    if (!props.newsData.enabled) {
      badges.push(<StatusBadge key={`${props.newsData._id}-inactive`} status="INACTIVO" badgeStyle="secondary" />);
    } else {
      badges.push(<StatusBadge key={`${props.newsData._id}-active`} status="ACTIVO" badgeStyle="primary" />);
    }
    return badges;
  };

  const saveChanges = (id) => {
    if (errorBody || errorTitle || errorPictureURL) {
      setError('No puede guardarse porque hay campos faltantes.');
      return;
    }

    const newsModifiedData = {
      ...props.newsData,
      title,
      body,
      pictureURL,
      enabled,
    };

    newsUtils.saveModificationsNews(newsModifiedData)
      .then(res => {
        if (res.success) {
          window.location.reload();
        } else {
          setError('Ocurrio un error guadando los datos');
        }
      });
  };

  const deleteUserHandler = (id) => {
    const confirm = window.confirm('¿Esta seguro que desea eliminar la noticia?');
    if (confirm) {
      // Delete the news
      newsUtils.deleteNews(id).then((res) => {
        if (res.success) {
          window.location.reload();
        } else {
          setError('Ocurrio un error eliminando la noticia.');
        }
      });
    }
  };

  const handleTitle = (e) => {
    const value = e.target.value;
    if (value) {
      setErrorTitle(null);
      setTitle(value);
    } else {
      setErrorTitle('El titulo no puede estar vacio.');
    }
  };

  const handlePictureURL = (e) => {
    const value = e.target.value;
    if (value) {
      setErrorPictureURL(null);
      setPictureURL(value);
    } else {
      setErrorPictureURL('El link de la foto no puede estar vacio.');
    }
  };

  const handleBody = (e) => {
    const value = e.target.value;
    if (value) {
      setErrorBody(null);
      setBody(value);
    } else {
      setErrorBody('El cuerpo de la noticia no puede estar vacio.');
    }
  };

  return (
    <div className="card card-custom-history" key="1">
      <div className="card-header card-header-history" id="headingOne">
        <h5 className="mb-0">
          <button className="btn btn-width-100"
            data-toggle="collapse" data-target={`#collapse-${props.newsData._id}`}
            aria-expanded="true" aria-controls="collapseOne">
            <table className="table table-custom-history">
              <tbody>
                <tr className="media media-custom">
                  <td className="media-left media-left-custom">
                    <span>
                      {getStatusBadge()}
                    </span>
                  </td>
                  <td className="media-body media-body-custom">
                    <h5 className="media-heading font-yellow text-bold hidden-xs">
                      {props.newsData.title}
                    </h5>
                    <span><strong>Fecha: </strong>
                      {utils.formatDate(props.newsData.date)}
                    </span>
                    <br />
                    <span><strong>Foto Incluida: </strong>
                      <a href={props.newsData.pictureURL}>Ver foto en Google Drive</a>
                    </span>
                    <br />
                    <span><strong>Notificacion: </strong>
                      {props.newsData.body}
                    </span>
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </button>
        </h5>
      </div>
      <div id={`collapse-${props.newsData._id}`}
        className="collapse collapse-custom" aria-labelledby="headingOne" data-parent="#accordion">
        <div className="card-body">
          <Slider title="Activada" checked={enabled} setChecked={() => setIsEnabled(!enabled)} />
          <div className="input-group mb-3 col-12 col-md-8 input-group-users">
            <label className="label-inputs">
              Titulo
              <input type="text"
                className="form-control" aria-describedby="basic-addon3"
                onChange={handleTitle}
                value={title}
                onBlur={handleTitle}
              />
            </label>
            <p className="error-login">{errorTitle}</p>
            <label className="label-inputs">
              Cuerpo
              <textarea
                className="form-control" aria-describedby="basic-addon3"
                onChange={handleBody}
                value={body}
                onBlur={handleBody}
              />
            </label>
            <p className="error-login">{errorBody}</p>
            <label className="label-inputs">
              Foto Incluida
              <input type="text"
                className="form-control" aria-describedby="basic-addon3"
                onChange={handlePictureURL}
                value={pictureURL}
                onBlur={handlePictureURL}
              />
            </label>
            <p className="error-login">{errorPictureURL}</p>
          </div>
          <div className="mb-3 col-12 col-md-8">
            <button className="btn btn-dark" onClick={() => saveChanges(props.newsData._id)}>
              <i className="material-icons icon-action icon-buttons-user">save</i>
              Guardar
            </button>
            <button className="btn btn-dark" style={{ marginLeft: '10px' }} onClick={() => deleteUserHandler(props.newsData._id)}>
              <i className="material-icons icon-action icon-buttons-user">delete</i>
               Eliminar
             </button>
            <p className="error-login">{error}</p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default NewsFeed;
