const status = {
    flow: ['RECIBIDO', 'EN PROCESO', 'PREPARADO', 'PREPARADO CON FALTANTES' ],
    defaultStatus: 'RECIBIDO',
    flowStyles: {
        'RECIBIDO': 'primary',
        'EN PROCESO': 'secondary',
        'PREPARADO': 'success',
        'PREPARADO CON FALTANTES': 'warning',
    }
};

export default status;