/**
 * Generates the local storage key for the receipts
 * @returns {string} key
 */
const getReceiptsLocalKey = () => {
  const receiptsVersion = '1';
  const customer = localStorage.getItem('customer');
  const receiptsKey = `receipts-${receiptsVersion}-${customer}`;
  return receiptsKey;
};

const saveReceipts = (receipts) => {
  const receiptsKey = getReceiptsLocalKey();
  localStorage.setItem(receiptsKey, JSON.stringify(receipts));
};

const getReceipts = () => {
  const receiptsKey = getReceiptsLocalKey();
  return JSON.parse(localStorage.getItem(receiptsKey)) || [];
};

const clearReceipts = () => {
  const receiptsKey = getReceiptsLocalKey();
  localStorage.removeItem(receiptsKey);
};

export default {
  getReceiptsLocalKey,
  saveReceipts,
  getReceipts,
  clearReceipts,
};
