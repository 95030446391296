import React from 'react';
import NavBar from '../../components/navbar/navBarComponent';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import MenuItem from '../../components/menu/menuItem';

const NewsFeedPage = (props) => {

  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'News Feed', link: '/news-feed' },
  ];

  return (
    <div id="main-news-feed" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page='news-feed-admin' items={breadcrumbItems} />
      <div className="menu-container">
        <MenuItem
          key="feed_admin"
          title="Admin Notificaciones"
          text="Administrar notificaciones"
          icon="admin_panel_settings"
          link="/news-feed-admin"
        />
        <MenuItem
          title="Crear Notificaciones"
          text="Crear nueva notificacion"
          icon="feed"
          link="/news-feed-create"
        />
      </div>
    </div>);
};

export default NewsFeedPage;
