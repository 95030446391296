import React, { useState } from 'react';
import '../history-orders/historyOrders.css';
import utils from '../../utils/product/product-utils';
import StatusBadge from '../status-badge/statusBadge';
import statusConfig from '../../config/statusFlow';
import { updateOrderStatusByNumber } from '../../utils/order/orderUtils';

const initialStatus = 'Seleccionar';

const AdminOrders = (props) => {
  const [newStatus, setNewStatus] = useState(props.orderData.status);
  const [showCollapse, setShowCollapse] = useState(false);
  const [error, setError] = useState(null);

  // This method generates the table with the details.
  const generateDetails = (details, orderNumber) => {
    const generateRowDetails = (detailItems) => {
      const detailItemsList = [];
      for (let i = 0; i < detailItems.length; i++) {
        detailItemsList.push(
          <tr
            key={`${orderNumber}-${detailItems[i].sku}-${detailItems[i].quantity}`}
          >
            <td>{detailItems[i].sku}</td>
            <td>{detailItems[i].product}</td>
            <td>{detailItems[i].specifications[0].Marca}</td>
            <td>{detailItems[i].specifications[0].Tamaño}</td>
            <td>{detailItems[i].quantity}</td>
            <td>{utils.formatCurrency(detailItems[i].price)}</td>
            <td>
              {utils.formatCurrency(
                parseFloat(detailItems[i].price) *
                  parseFloat(detailItems[i].quantity)
              )}
            </td>
          </tr>
        );
      }
      return detailItemsList;
    };

    return (
      <table id="details">
        <thead>
          <tr key={`details-${orderNumber}`}>
            <th>ID</th>
            <th>Producto</th>
            <th>Marca</th>
            <th>Tamaño</th>
            <th>Cantidad</th>
            <th>Precio</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>{generateRowDetails(details)}</tbody>
      </table>
    );
  };

  const generateSelectStatus = () => {
    const statusList = statusConfig.flow;
    const statusOptions = [];
    // Add the first status option to select
    statusOptions.push(
      <option key={initialStatus} value={'select'}>
        {initialStatus}
      </option>
    );

    for (let i = 0; i < statusList.length; i++) {
      statusOptions.push(
        <option key={statusList[i]} value={statusList[i]}>
          {statusList[i]}
        </option>
      );
    }
    return statusOptions;
  };

  const setStatus = (e) => {
    const status = e.target.value;
    if (status) {
      setNewStatus(status);
    }
  };

  const saveChanges = async (number) => {
    if (newStatus !== props.orderData.status) {
      const params = {
        number,
        status: newStatus,
      };
      const response = await updateOrderStatusByNumber(params);
      if (response.success) {
        setCollapseStatus();
      } else {
        setError('Hubo un error guardando el nuevo estado.');
      }
    }
  };

  const setCollapseStatus = () => {
    setShowCollapse(!showCollapse);
  };

  return (
    <div className="card card-custom-history" key={props.orderData.number}>
      <div className="card-header card-header-history" id="headingOne">
        <h5 className="mb-0">
          <button
            className="btn btn-width-100"
            data-toggle="collapse"
            data-target={`#collapse-${props.orderData.number}`}
            aria-expanded="true"
            aria-controls="collapseOne"
            onClick={setCollapseStatus}
          >
            <table className="table table-custom-history">
              <tbody>
                <tr className="media media-custom">
                  <td className="media-left media-left-custom">
                    <h5>{`Orden #${props.orderData.number}`}</h5>
                    <span>
                      <StatusBadge status={newStatus} />
                    </span>
                  </td>
                  <td className="media-body media-body-custom">
                    <h5 className="media-heading font-yellow text-bold hidden-xs">
                      {`TOTAL: ${utils.formatCurrency(props.orderData.total)}`}
                    </h5>
                    <span>
                      <strong>Cliente: </strong>
                      {props.orderData.user.fullName}
                    </span>
                    <br />
                    <span>
                      <strong>Domicilio Envio: </strong>
                      {props.orderData.deliveryTo}
                    </span>
                    <br />
                    <span>
                      <strong>Fecha Envio: </strong>
                      {utils.formatDate(props.orderData.deliveryDate)}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </button>
        </h5>
      </div>
      <div
        id={`collapse-${props.orderData.number}`}
        className={
          !showCollapse
            ? 'collapse collapse-custom'
            : 'collapse collapse-custom show'
        }
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        <div className="card-body">
          {generateDetails(
            props.orderData.orderDetails,
            props.orderData.number
          )}
          <p className="comments-text mb-1">
            {' '}
            <strong>Comentarios:</strong> {props.orderData.observations}
          </p>
          <div className="col-12 col-md-8 p-0">
            <div className="form-group mb-3 col-12 col-md-3 p-0">
              <label htmlFor="estado">Nuevo Estado Pedido:</label>
              <select
                id="estado"
                className="form-control"
                onChange={setStatus}
                value={newStatus}
              >
                {generateSelectStatus()}
              </select>
            </div>
            {newStatus !== props.orderData.status && (
              <>
                <div className="mb-3">
                  <p className="comments-text mb-3">
                    <strong>Cambio de estado:</strong>
                  </p>
                  <StatusBadge inline={true} status={props.orderData.status} />
                  <i className="material-icons icon-action icon-buttons-user">
                    arrow_right_alt
                  </i>
                  <StatusBadge inline={true} status={newStatus} />
                </div>
                <button
                  className="btn btn-dark"
                  onClick={() => saveChanges(props.orderData.number)}
                >
                  <i className="material-icons icon-action icon-buttons-user">
                    save
                  </i>
                  Guardar
                </button>
                <p className="error-login">{error}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOrders;
