import React from 'react';
import NavBar from '../../components/navbar/navBarComponent';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import Title from '../../components/title-tile/titleTileComponent';

const ReceiptsUserPage = (props) => {
  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'Recibos', link: '/receipts' },
    { name: 'Mis Recibos Enviados', link: '/receipts-user-page' },
  ];

  return (
    <div id="main-receipts-feed" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page="receipts-feed-admin" items={breadcrumbItems} />
      <Title
        message="Esta página esta en construcción estara disponible proximamente! :)"
        theme="info"
      />
    </div>
  );
};

export default ReceiptsUserPage;
