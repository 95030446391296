import axios from 'axios';
import config from '../../config/main';

const getAllUserReceipts = async (userId) => {
  try {
    const returns = await axios({
      method: 'GET',
      url: `${config.apiEndpoint}/receipts/${userId}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return returns.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getCreditCards = async (userId) => {
  try {
    const returns = await axios({
      method: 'GET',
      url: `${config.apiEndpoint}/credit-cards`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return returns.data;
  } catch (err) {
    throw new Error(err);
  }
};

const saveReceipts = async (data) => {
  try {
    const returns = await axios({
      method: 'POST',
      url: `${config.apiEndpoint}/receipts`,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return returns.data;
  } catch (err) {
    throw new Error(err);
  }
};

export default {
  saveReceipts,
  getCreditCards,
  getAllUserReceipts,
};
