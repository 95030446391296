import React, { useState } from 'react';
import './ordersAdminPage.css';
import NavBar from '../../components/navbar';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import PageControls from '../../components/table-pagination/pageControls';
import { getPlatformOrders } from '../../utils/order/orderUtils';
import AdminSearch from '../../components/admin-search/adminSearchComponent';
import Search from '../../components/search/searchComponent';
import AdminOrders from '../../components/admin-orders/adminOrdersComponent';
import Title from '../../components/title-tile/titleTileComponent';

const PAGE_SIZE = 10;

const OrdersAdminPage = (props) => {
  const [orders, setOrders] = useState(null);
  const [ordersVisible, setOrdersVisible] = useState(null);
  const [page, setPageNumber] = useState(1);
  const [isFiltered, setIsFiltered] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [error, setError] = useState(null);
  const [searchVisible, setSearchVisible] = useState(true);
  const [searchStr, setSearchStr] = useState(null);

  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'Admin Ordenes', link: '/admin' },
  ];

  const getOrders = async function () {
    const params = {
      dateFrom,
      dateTo,
      status: orderStatus,
    };

    if (dateTo && dateFrom) {
      setError(null);
      const ordersData = await getPlatformOrders(params);
      if (ordersData.success) {
        setOrders(ordersData.data);
        const firstPageOrders = ordersData.data.slice(0, PAGE_SIZE);
        setOrdersVisible(firstPageOrders);
        setSearchVisible(false);
      }
    } else {
      setError('Verifique los parametros de busqueda.');
    }
  };

  const generatePlatformOrdersLists = (orders) => {
    const orderList = [];
    if (orders) {
      for (let i = 0; i < orders.length; i++) {
        orderList.push(
          <AdminOrders
            key={`order-${orders[i].number}`}
            orderData={orders[i]}
          />
        );
      }
    }
    return orderList;
  };

  const navigateNextPage = () => {
    const maxPage = Math.ceil(orders.length / PAGE_SIZE);
    const nextPage = page + 1;
    if (nextPage <= maxPage) {
      const nextVisibleItems = orders.slice(
        page * PAGE_SIZE,
        nextPage * PAGE_SIZE
      );
      setOrdersVisible(nextVisibleItems);
      setPageNumber(nextPage);
    }
  };

  const navigatePrevPage = () => {
    const prevPage = page - 1;
    if (prevPage >= 1) {
      const previousVisibleItems = orders.slice(
        (prevPage - 1) * PAGE_SIZE,
        prevPage * PAGE_SIZE
      );
      setOrdersVisible(previousVisibleItems);
      setPageNumber(prevPage);
    }
  };

  const filterOrders = (e) => {
    const filterStr = e.target.value;
    setSearchStr(filterStr);

    if (filterStr.length > 0) {
      const filteredOrders = orders.filter((el) => {
        return (
          el.number.toString().includes(filterStr) ||
          new Date(el.deliveryDate).toLocaleDateString().includes(filterStr)
        );
      });
      setOrdersVisible(filteredOrders);
      setIsFiltered(true);
    } else {
      setOrdersVisible(orders);
      setIsFiltered(false);
    }
  };

  const setSearchStatus = () => {
    setSearchVisible(!searchVisible);
  };

  return (
    <div id="main-admin" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page="ordersAdmin" items={breadcrumbItems} />
      <h3>Gestion de Pedidos</h3>
      <p className="aclaration-text">
        Actualizaciones y seguimientos de pedidos realizados en la plataforma.
      </p>
      <div id="accordion">
        <div className="card">
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <i
                className="material-icons icon-action icon-check collapsible-icon"
                style={{ display: searchVisible ? 'none' : 'block' }}
              >
                expand_less
              </i>
              <i
                className="material-icons icon-action icon-check collapsible-icon"
                style={{ display: searchVisible ? 'block' : 'none' }}
              >
                expand_more
              </i>
              <button
                className="btn"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                onClick={setSearchStatus}
              >
                <i className="material-icons icon-action icon-check">search</i>
                Busqueda de Pedidos
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            className={searchVisible ? 'collapse show' : 'collapse'}
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div className="card-body">
              <AdminSearch
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                setStatus={setOrderStatus}
              />
              <button
                className="btn btn-dark col-12 col-md-3"
                onClick={() => getOrders()}
                disabled={false}
              >
                Buscar Pedidos
                <i className="material-icons icon-action icon-check">search</i>
              </button>
              <p className="error-login">{error}</p>
            </div>
          </div>
        </div>
      </div>
      {!searchVisible && ordersVisible && ordersVisible.length > 0 && (
        <>
          <Search
            placeholder="Busqueda por ID o Fecha de Envio"
            searchCallback={filterOrders}
            value={searchStr}
          />
          {generatePlatformOrdersLists(ordersVisible)}
          <PageControls
            isFiltered={isFiltered}
            currentPage={page}
            nextPageHandler={navigateNextPage}
            prevPageHandler={navigatePrevPage}
            totalPages={orders ? Math.ceil(orders.length / PAGE_SIZE) : 0}
          />
        </>
      )}
      {ordersVisible && ordersVisible.length === 0 && (
        <Title
          hide={searchVisible}
          message="No hay resultados para la busqueda."
        />
      )}
    </div>
  );
};

export default OrdersAdminPage;
