import React, { useState } from 'react';
import StatusBadge from '../status-badge/statusBadge';
import utils from '../../utils/product/product-utils';
import { deleteUser, saveModificationsUser } from '../../utils/users/users';
import './userListComponent.css';
import Slider from '../slider/slider';

const UserList = (props) => {
  const [isAuthorized, setIsAuthorized] = useState(!!props.userData.active);
  const [isAdmin, setIsAdmin] = useState(!!props.userData.tags.includes('admin'));
  const [salesAgentInput, setSalesAgentInput] = useState(props.userData.salesAgent);
  const [errorSalesAgent, setErrorSalesAgent] = useState(null);
  const [internalIdInput, setInternalIdInput] = useState(props.userData.internalId);
  const [errorInternalId, setErrorInternalId] = useState(null);
  const [error, setError] = useState(null);

  const getStatusBadge = () => {
    const badges = [];

    if (!props.userData.active) {
      badges.push(<StatusBadge key={`${props.userData._id}-inactive`} status="INACTIVO" badgeStyle="secondary" />);
    } else {
      badges.push(<StatusBadge key={`${props.userData._id}-active`} status="ACTIVO" badgeStyle="primary" />);
    }

    // Admin case
    if (props.userData.tags.includes('admin')) {
      badges.push(<StatusBadge key={`${props.userData._id}-admin`} status="ADMIN" badgeStyle="danger" />);
    }

    return badges;
  };

  const saveChanges = (id) => {
    if (!errorInternalId && !errorSalesAgent && salesAgentInput && internalIdInput) {
      const tags = [];
      if (isAdmin) {
        tags.push('admin');
      }

      const data = {
        id,
        tags,
        active: isAuthorized,
        internalId: internalIdInput,
        salesAgent: salesAgentInput
      };

      saveModificationsUser(data).then((res) => {
        if (res.success) {
          window.location.reload();
        } else {
          setError('Ocurrio un error guadando los datos del usuario.');
        }
      });
    } else {
      setError('No puede guardarse porque hay campos faltantes.');
    }
  };

  const deleteUserHandler = (id) => {
    const confirm = window.confirm('¿Esta seguro que desea eliminar el usuario?');
    if (confirm) {
      // Delete the user
      deleteUser(id).then((res) => {
        if (res.success) {
          window.location.reload();
        } else {
          setError('Ocurrio un error eliminando el usuario.');
        }
      });
    }
  };

  const handleSalesAgent = (e) => {
    const value = e.target.value;
    if (value) {
      setErrorSalesAgent(null);
      setSalesAgentInput(value);
    } else {
      setErrorSalesAgent('El agente de ventas no puede estar vacio.');
    }
  };

  const handleInternalId = (e) => {
    const value = e.target.value;
    if (value) {
      setErrorInternalId(null);
      setInternalIdInput(value);
    } else {
      setErrorInternalId('El numero de cuenta no puede estar vacio.');
    }
  };

  const handleSetAdmin = (e) => {
    const value = e.target.value;
    if (value) {
      setIsAdmin(!isAdmin);
    }
  };

  const handleSetActive = (e) => {
    const value = e.target.value;
    if (value) {
      setIsAuthorized(!isAuthorized);
    }
  };

  return (
    <div className="card card-custom-history" key="1">
      <div className="card-header card-header-history" id="headingOne">
        <h5 className="mb-0">
          <button className="btn btn-width-100"
            data-toggle="collapse" data-target={`#collapse-${props.userData._id}`}
            aria-expanded="true" aria-controls="collapseOne">
            <table className="table table-custom-history">
              <tbody>
                <tr className="media media-custom">
                  <td className="media-left media-left-custom">
                    <span>
                      {getStatusBadge()}
                    </span>
                  </td>
                  <td className="media-body media-body-custom">
                    <h5 className="media-heading font-yellow text-bold hidden-xs">
                      {props.userData.fullName}
                    </h5>
                    <span><strong>Email: </strong>
                      {props.userData.email}
                    </span>
                    <br />
                    <span><strong>CUIT: </strong>
                      {props.userData.taxIdentifier}
                    </span>
                    <br />
                    <span><strong>Domicilio: </strong>
                      {props.userData.address}
                    </span>
                    <br />
                    {props.userData.city && props.userData.province &&
                      <>
                        <span><strong>Localidad/Provincia: </strong>
                          {props.userData.city + '-' + props.userData.province}
                        </span>
                        <br />
                      </>}
                    <span><strong>Fecha Alta: </strong>
                      {utils.formatDate(props.userData.createdAt)}
                    </span>
                    <br />
                    <span><strong>Telefono: </strong>
                      {props.userData.phone}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </button>
        </h5>
      </div>
      <div id={`collapse-${props.userData._id}`}
        className="collapse collapse-custom" aria-labelledby="headingOne" data-parent="#accordion">
        <div className="card-body">
          <Slider title="Activo" checked={isAuthorized} setChecked={handleSetActive} />
          <Slider title="Admin" checked={isAdmin} setChecked={handleSetAdmin} />
          <div className="input-group mb-3 col-12 col-md-8 input-group-users">
            <label className="label-inputs">
              Mail Responsable
              <input type="text"
                className="form-control" aria-describedby="basic-addon3"
                onChange={handleSalesAgent}
                value={salesAgentInput}
                onBlur={handleSalesAgent}
              />
            </label>
            <p className="error-login">{errorSalesAgent}</p>
            <label className="label-inputs">
              Nro Cuenta
              <input type="text"
                className="form-control" aria-describedby="basic-addon3"
                onChange={handleInternalId}
                value={internalIdInput}
                onBlur={handleInternalId}
              />
            </label>
            <p className="error-login">{errorInternalId}</p>
          </div>
          <div className="mb-3 col-12 col-md-8">
            <button className="btn btn-dark" onClick={() => saveChanges(props.userData._id)}>
              <i className="material-icons icon-action icon-buttons-user">save</i>
              Guardar
            </button>
            <button className="btn btn-dark" style={{ marginLeft: '10px' }} onClick={() => deleteUserHandler(props.userData._id)}>
              <i className="material-icons icon-action icon-buttons-user">delete</i>
              Eliminar
            </button>
            <p className="error-login">{error}</p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default UserList;
