import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './loginPage.css';

import Login from '../../components/login/loginComponent';
import SignUpPage from '../signupPage/signupPage';
import PasswordRecovery from '../../components/password-recovery/passwordRecoveryComponent';
import SetNewPassword from '../../components/password-recovery/setNewPasswordComponent';
import SetPasswordReset from '../../components/password-recovery/setNeedsResetComponent';
import OrderPage from '../../pages/orderPage';
import Menu from '../../components/menu';
import CartPage from '../cartPage';
import CheckoutPage from '../checkoutPage/checkoutPage';
import SuccessPage from '../sucessPage/sucessPage';
import OrderHistory from '../orderHistoryPage/orderHistoryPage';
import QuestionPage from '../questionsPage/questionsPage';
import PrivateRoute from '../../components/routes/privateRouteComponent';
import AdminRoute from '../../components/routes/adminRouteComponent';
import PromoPage from '../promoPage/promoPage';
import UsersPage from '../usersAdminPage/usersPage';
import OrdersAdminPage from '../ordersAdminPage/ordersAdminPage';
import AccountPage from '../accountPage/accountPage';
import NewsPage from '../newsPage/newsPage';
import NewsFeedPage from '../newsFeedAdminPage/newsFeedPage';
import NewsFeedAdminPage from '../newsFeedAdminPage/newsFeedAdminPage';
import NewsFeedCreatePage from '../newsFeedAdminPage/newsFeedCreatePage';
import ReceiptsPage from '../receipts/receiptsPage';
import ReceiptsCreatePage from '../receipts/receiptsCreatePage';
import ReceiptsUserPage from '../receipts/receiptsUserPage';
import UserAccountPage from '../userAccountPage/userAccountPage';

const LoginPage = (props) => {
  return (
    <Router>
      <Switch>
        <PrivateRoute
          exact
          path="/"
          component={Menu}
          cart={props.cart}
          news={props.news}
        />
        <PrivateRoute
          path="/menu"
          component={Menu}
          cart={props.cart}
          news={props.news}
        />
        <Route path="/login" component={Login} />
        <Route path="/register" component={SignUpPage} />
        <Route path="/recovery" component={PasswordRecovery} />
        <Route path="/reset" component={SetNewPassword} />
        <Route path="/password" component={SetPasswordReset} />
        <PrivateRoute path="/order" component={OrderPage} cart={props.cart} />
        <PrivateRoute
          path="/user"
          component={UserAccountPage}
          cart={props.cart}
        />
        <PrivateRoute
          path="/history"
          component={OrderHistory}
          cart={props.cart}
        />
        <PrivateRoute path="/cart" component={CartPage} cart={props.cart} />
        <PrivateRoute
          path="/checkout"
          component={CheckoutPage}
          cart={props.cart}
          setOrder={props.setOrder}
        />
        <PrivateRoute
          path="/success"
          component={SuccessPage}
          cart={props.cart}
          order={props.order}
        />
        <PrivateRoute
          path="/questions"
          component={QuestionPage}
          cart={props.cart}
        />
        <PrivateRoute path="/promo" component={PromoPage} cart={props.cart} />
        <PrivateRoute
          path="/account"
          component={AccountPage}
          cart={props.cart}
        />
        <PrivateRoute path="/news" component={NewsPage} cart={props.cart} />
        <PrivateRoute
          path="/receipts"
          component={ReceiptsPage}
          cart={props.cart}
        />
        <PrivateRoute
          path="/receipts-user-admin"
          component={ReceiptsUserPage}
          cart={props.cart}
        />
        <PrivateRoute
          path="/receipts-create"
          component={ReceiptsCreatePage}
          cart={props.cart}
        />
        <AdminRoute path="/users" component={UsersPage} cart={props.cart} />
        <AdminRoute
          path="/admin"
          component={OrdersAdminPage}
          cart={props.cart}
        />
        <AdminRoute
          path="/news-feed"
          component={NewsFeedPage}
          cart={props.cart}
        />
        <AdminRoute
          path="/news-feed-admin"
          component={NewsFeedAdminPage}
          cart={props.cart}
        />
        <AdminRoute
          path="/news-feed-create"
          component={NewsFeedCreatePage}
          cart={props.cart}
        />
      </Switch>
    </Router>
  );
};

export default LoginPage;
