import axios from 'axios';
import config from '../../config/main';

const getAccountInfo = async (internalId) => {
  try {
    const account = await axios({
      method: 'GET',
      url: `${config.apiEndpoint}/account/${internalId}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return account.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getAccountDetails = async (internalId) => {
  try {
    const account = await axios({
      method: 'GET',
      url: `${config.apiEndpoint}/account/details/${internalId}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return account.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getAccountArticlesDetails = async (invoiceID) => {
  try {
    const account = await axios({
      method: 'GET',
      url: `${config.apiEndpoint}/account/articles/${invoiceID}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return account.data;
  } catch (err) {
    throw new Error(err);
  }
};

export { getAccountInfo, getAccountDetails, getAccountArticlesDetails };
