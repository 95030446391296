import React from 'react';
const Btn = (props) => {
  return (
    <button
      type={props.type}
      className={props.cssClass}
      onClick={props.fn1}
      disabled={props.disabled}
    >
      {props.name}
    </button>
  );
};

export default Btn;
