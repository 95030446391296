import React, { useEffect, useState } from 'react';
import Loader from '../loader/loaderComponent';
import productUtils from '../../utils/product/product-utils';
import promoConfig from '../../config/promoConfig';
import utils from '../../utils/product/product-utils';
import './promoComponent.css';
import config from '../../config/tableConfig';

const PromoList = (props) => {
    const [promos, setPromos] = useState([]);
    const [products, setProducts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function getPromos() {
            const csvPromos = await productUtils.getCSVPromos();
            const JSONPromos = productUtils.csvToJSON(csvPromos);
            return JSONPromos;
        }
        getPromos().then(async promos => {
            if (promos.length > 0) {
                // Set the promos to render them
                setPromos(promos);

                // Get the products to search and add to the cart
                const config = await productUtils.getBusinessConfig();
                const csvProducts = await productUtils.getCSV(config.data.productsURL);
                const JSONProducts = productUtils.csvToJSON(csvProducts);
                setProducts(JSONProducts);
                setIsLoaded(true);
            }
        });
    }, []);

    const getProductById = (id) => {
        return products.find(elem => elem[config.keyColumn] === id);
    };

    const isPromoAlreadyInCart = (promoName, cart) => {
        let isInCart = false;
        for (let i = 0; i < cart.length; i++) {
            if (cart[i].Promo === promoName) {
                isInCart = true;
                break;
            }
        }
        return isInCart;
    };

    const buyPromotion = (id) => {
        const promoID = parseInt(id) - 1;
        const promoName = promos[promoID][promoConfig.textField];
        const quantityPromos = prompt(`¿Cuantas promociones de ${promoName} desea?`, '1');
        const currentCart = props.cart.getItems();
        const isPromoInCart = isPromoAlreadyInCart(promoName, currentCart);
        // We should check not already in the cart and valid quantity
        if (parseInt(quantityPromos) > 0 && !isPromoInCart) {
            const productsIds = promos[promoID][promoConfig.productsField].split(' ');
            const price = promos[promoID][promoConfig.priceField].split(' ');
            const quantityItems = promos[promoID][promoConfig.quantityField].split(' ');
            const extraItems = parseInt(promos[promoID][promoConfig.extraUnitsField]);
            for (let i = 0; i < productsIds.length; i++) {
                const product = getProductById(productsIds[i].trim());
                if (product) {
                    props.cart.addItem({ product, 'Cantidad': parseInt(quantityItems[i] * quantityPromos), 'Subtotal': parseFloat(price[i] * quantityPromos), 'Promo': promoName });
                    if (extraItems > 0) {
                        props.cart.addItem({ product, 'Cantidad': parseInt(extraItems * quantityPromos), 'Subtotal': 0, 'Promo': promoName });
                    }
                } else {
                    setError('Ocurrio un error agregando la promocion. Contacte al administrador.');
                    props.cart.clearCart();
                    return;
                }
            }
            // If everything goes fine then we show the cart.
            props.history.push('/cart');
        } else {
            alert('La promocion ya esta en el carrito. Elimine los items e intente comprar nuevamente.');
        }
    };

    const generatePromos = (promosItems) => {
        const promotions = [];
        for (let i = 0; i < promosItems.length; i++) {
            promotions.push(
                <div key={promosItems[i][promoConfig.keyField]} className="col-12 col-md-3 card-custom-promo card-flex-promo">
                    <div className="card">
                        <img className="card-img-top" src={promosItems[i][promoConfig.imageField]} alt={promosItems[i][promoConfig.textField]} />
                        <div className="card-body">
                            <p className="card-text">{promosItems[i][promoConfig.textField]}.</p>
                            <div>
                                <span className="badge badge-secondary custom-badge-promo">
                                    {utils.formatCurrency(promosItems[i][promoConfig.priceField].split(' ')[0])}
                                </span>
                            </div>
                        </div>
                    </div>
                    {promosItems[i][promoConfig.quantityField] && promosItems[i][promoConfig.quantityField].split(' ')[0] > 0 &&
                        <button className="btn btn-dark btn-width-100 btn-buy-promo"
                            onClick={() => buyPromotion(promosItems[i][promoConfig.keyField])}>
                            Comprar
                        </button>
                    }
                </div>
            );
        }
        return promotions;
    };



    return (
        <>
            <Loader show={!isLoaded} />
            <p className="error-cart">{error}</p>
            <div style={{ display: isLoaded ? 'flex' : 'none' }} className="promo-container">
                {generatePromos(promos)}
            </div>
        </>
    )

};

export default PromoList;