/**
 * Configurations for the table.
 * The key column is important to be configured.
 * type should include the column name and the data type to render.
 */
const config = {
  pageSize: 50,
  stockCheck: true,
  keyColumn: 'ID',
  stockColumn: 'Stock\r',
  priceColumn: 'Precio',
  quantityColumn: 'Cantidad',
  promoColumn: 'Promo',
  sizeColumn: 'Tamaño',
  searchCriteria: {
    brand: 'Marca',
    product: 'Producto',
  },
  format: {
    Precio: 'money',
    Subtotal: 'money',
    Total: 'money',
  },
  style: {
    Precio: 'right',
    'Stock\r': 'right',
    Subtotal: 'right',
    Cantidad: 'right',
  },
  noMobile: {
    ID: true,
  },
  locales: {
    currency: 'ARS',
    region: 'es-AR',
  },
  cart: {
    columns: ['Cantidad', 'Subtotal', ''],
    actionColumns: [''],
    hidden: {
      'Stock\r': true,
      ID: true,
    },
  },
};

export default config;
