import React, { useEffect, useState } from 'react';
import NavBar from '../../components/navbar';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import UserList from '../../components/user-list/userListComponent';
import Search from '../../components/search/searchComponent';
import { getUsers } from '../../utils/users/users';
import PageControls from '../../components/table-pagination/pageControls';
import config from '../../config/tableConfig';

const PAGE_SIZE = 5;

const UsersPage = (props) => {
  const [users, setUsers] = useState(null);
  const [usersVisible, setUsersVisible] = useState(null);
  const [page, setPageNumber] = useState(1);
  const [isFiltered, setIsFiltered] = useState(null);
  const [searchStr, setSearchStr] = useState(null);

  useEffect(() => {
    async function getAllUsers() {
      const users = await getUsers();
      return users;
    }
    getAllUsers().then((res) => {
      if (res.success) {
        setUsers(res.data);
        const firstPageUsers = res.data.slice(0, PAGE_SIZE);
        setUsersVisible(firstPageUsers);
      }
    });
  }, []);

  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'Gestion Usuarios', link: '/users' },
  ];

  const navigateNextPage = () => {
    const maxPage = Math.ceil(users.length / PAGE_SIZE);
    const nextPage = page + 1;
    if (nextPage <= maxPage) {
      const nextVisibleItems = users.slice(
        page * PAGE_SIZE,
        nextPage * PAGE_SIZE
      );
      setUsersVisible(nextVisibleItems);
      setPageNumber(nextPage);
    }
  };

  const navigatePrevPage = () => {
    const prevPage = page - 1;
    if (prevPage >= 1) {
      const previousVisibleItems = users.slice(
        (prevPage - 1) * PAGE_SIZE,
        prevPage * PAGE_SIZE
      );
      setUsersVisible(previousVisibleItems);
      setPageNumber(prevPage);
    }
  };

  const filterUsers = (e) => {
    const filterStr = e.target.value.toLowerCase();
    setSearchStr(filterStr);
    const words = filterStr.split(' ');

    if (filterStr.length > 0) {
      let filteredUsers = users;
      for (let i = 0; i < words.length; i++) {
        filteredUsers = users.filter((el) => {
          if (el.fullName && el.internalId && el.email && el.createdAt) {
            return (
              el.fullName.toString().toLowerCase().includes(words[i]) ||
              el.internalId.toString().toLowerCase().includes(words[i]) ||
              el.email.toString().toLowerCase().includes(words[i]) ||
              new Date(el.createdAt)
                .toLocaleDateString(config.locales.region)
                .includes(words[i])
            );
          }
          return null;
        });
      }
      setUsersVisible(filteredUsers);
      setIsFiltered(true);
    } else {
      setUsersVisible(users);
      setIsFiltered(false);
    }
  };

  const generateUsersList = (userListVisible) => {
    const usersList = [];
    if (userListVisible) {
      for (let i = 0; i < userListVisible.length; i++) {
        usersList.push(
          <UserList
            key={userListVisible[i]._id}
            userData={userListVisible[i]}
          />
        );
      }
    }
    return usersList;
  };

  return (
    <div id="main-users-admin" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page="users" items={breadcrumbItems} />
      <h3>Gestion de Usuarios</h3>
      <Search
        text="Usuario"
        placeholder="Nombre, Email, Fecha de Alta, Nro Cuenta"
        searchCallback={filterUsers}
        value={searchStr}
      />
      {generateUsersList(usersVisible)}
      <PageControls
        isFiltered={isFiltered}
        currentPage={page}
        nextPageHandler={navigateNextPage}
        prevPageHandler={navigatePrevPage}
        totalPages={users ? Math.ceil(users.length / PAGE_SIZE) : 0}
      />
    </div>
  );
};

export default UsersPage;
