import React, {useState, useRef, useEffect} from 'react';
import NavBar from '../navbar';
import defaultLogo from '../../assets/img/default-logo-new.png';
import productUtils from '../../utils/product/product-utils';
import { isValidEmail } from '../../utils/users/users';
import Title from '../title-tile/titleTileComponent';
import { sendRecoveryEmail } from '../../utils/users/users';

const PasswordRecovery = () => {
    const [error, setError] = useState(null);
    const [logoURL, setLogoURL] = useState(defaultLogo);
    const [success, setSuccess] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        async function getBusinessData () {
            const config = await productUtils.getBusinessConfig();
            return config;
        }
        getBusinessData().then(config => {
            if (config.success && config.data.logoURL) {
                setLogoURL(config.data.logoURL);
            }
        });
    }, []);

    const emailInput = useRef();

    const submitRecover = (e) => {
        e.preventDefault();
        const email = emailInput.current.value;
        const isValid = isValidEmail(email);

        if (email.length > 0 && isValid) {
            // Clear previous errors
            setError(null);
            setButtonDisabled(true);

            try {
                sendRecoveryEmail(email).then(res => {
                    if (res.success) {
                        setSuccess(true);
                    }
                });
            } catch (e) {
                setButtonDisabled(false);
                setError('Ocurrio un problema enviando el email');
            }
        } else {
            setButtonDisabled(false);
            setError('El email esta vacio o es incorrecto.')
        }
    };

    return (
        <div>
            <NavBar></NavBar>
            <div className="auth-wrapper bg-light">
                <div className="auth-inner">
                    <form>
                        <img className="img-fluid padding-middle" src={logoURL} alt="Logo"></img>
                        <div className="form-group">
                            <label>Email Registrado</label>
                            <input ref={emailInput} type="email" className="form-control" placeholder="email@example.com" disabled={success}/>
                        </div>

                        <button type="submit" className="btn btn-dark btn-block" onClick={submitRecover} disabled={buttonDisabled}>Recuperar</button>
                        <p className="forgot-password text-center">
                            ¿Aun no estas registrado? <a href="/register"> Ir a Registro</a>
                        </p>
                        <p className="forgot-password text-center">
                            ¿Ya estas registrado? <a href="/login"> Ir a Login</a>
                        </p>
                        <p className="error-login text-center">{error}</p>
                    </form>
                    <Title
                        message='Se ha enviado un email a la cuenta provista con los pasos de recuperacion.'
                        theme='success'
                        hide={!success}
                    ></Title>
                </div>
            </div>
        </div>
    );
};

export default PasswordRecovery;