import React, { useState, useEffect } from 'react';
import './receiptForm.css';
import NavBar from '../navbar/navBarComponent';
import Title from '../title-tile/titleTileComponent';
import ReceiptsTable from './receiptsTable';
import ReceiptAddForm from './receiptAddForm';
import ReceiptsUtils from '../../utils/receipts';
import salesData from '../../config/sales';
import receiptLocal from './receipts';

let errorArray = [];

const ReturnForm = (props) => {
  const [error, setError] = useState(null);
  const [receipts, setReceipts] = useState([]);

  const [observations, setObservations] = useState(null);
  const [errorObservations, setErrorObservations] = useState(null);

  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState(false);

  // Load the receipts if they're on local storage
  useEffect(() => {
    const localReceipts = receiptLocal.getReceipts();
    setReceipts(localReceipts);
  }, []);

  /**
   * Gets the seller assigned to the customer
   * @param {int} internalId
   */
  const getSalesPerson = (internalId) => {
    const salesPersons = Object.keys(salesData);
    salesPersons.forEach((seller) => {
      if (salesData[seller].includes(internalId)) {
        return seller;
      }
    });
    return salesPersons[0];
  };

  /**
   * Forms handlers
   */
  const handleObservations = (e) => {
    const value = e.target.value;
    const validation = `Las observaciones no pueden tener mas de 200 caracteres.\n`;

    const clearError = (validation) => {
      errorArray = errorArray.filter((elem) => elem !== validation);
    };

    if (value.length <= 200) {
      setObservations(value);
      setErrorObservations(null);
      clearError(validation);
    } else {
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorObservations(validation);
    }
  };

  /**
   * Method to save the return
   * @returns {boolean}
   */
  const saveReceipts = () => {
    // If there are errors show and return
    if (errorArray.length > 0) {
      setError(errorArray);
      return;
    }
    setDisabled(true);
    setError([]);

    const receiptsData = {
      date: new Date().toISOString(),
      observations: observations || 'Ninguna',
      receipts,
      user: props.user,
      status: 'PENDIENTE',
      tags: [],
      salesPerson: getSalesPerson(props.user.internalId),
    };

    ReceiptsUtils.saveReceipts(receiptsData)
      .then((res) => {
        if (res.success) {
          setSuccess(true);
          receiptLocal.clearReceipts();
        }
      })
      .catch((err) => {
        window.alert(err.message);
        setError([
          'Ocurrio un fallo guardando los recibos. Contacte al administrador.',
        ]);
        setDisabled(false);
      });
  };

  /**
   * Validates if all the fields are included
   */
  const validateCreate = () => {
    let isValid = true;
    if (errorArray.length > 0) isValid = false;
    if (!receipts.length) isValid = false;
    return isValid;
  };

  const generateGeneralError = (errorList) => {
    const errorRender = [];
    if (errorList) {
      for (let i = 0; i < errorList.length; i++) {
        errorRender.push(
          <li key={i} className="error-signup-general">
            {errorList[i]}
          </li>
        );
      }
    }
    return errorRender;
  };

  const deleteReceipt = (index) => {
    const newReceipts = receipts.filter((elem, i) => i !== index);
    setReceipts([...newReceipts]);
    receiptLocal.saveReceipts(newReceipts);
  };

  return (
    <div>
      <NavBar />
      <div className="auth-wrapper bg-light">
        <div className="form-news">
          <form>
            <ReceiptAddForm receipts={receipts} setReceipts={setReceipts} />
            <ReceiptsTable receipts={receipts} delete={deleteReceipt} />
            <div className="form-group observations">
              <label>Observaciones</label>
              <textarea
                onChange={handleObservations}
                onBlur={handleObservations}
                className="form-control"
                aria-label="Observaciones"
              />
              <p className="error-login">{errorObservations}</p>
            </div>
            <button
              type="button"
              className="btn btn-dark btn-block btn-save"
              onClick={saveReceipts}
              disabled={!validateCreate() || disabled}
            >
              Enviar Cupones
            </button>
            <ul className="error-list-general">
              {generateGeneralError(error)}
            </ul>
          </form>
          <div
            className="success-signup form-width"
            style={{ display: success ? 'block' : 'none' }}
          >
            <Title
              message="Los cupones se enviaron correctamente!"
              theme="success"
            />
            <a
              className="btn btn-dark btn-block btn-save"
              href="/receipts"
              role="button"
            >
              Volver
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnForm;
