import axios from 'axios';
import config from '../../config/main';

const getUsers = async () => {
  try {
    const users = await axios({
      method: 'GET',
      url: `${config.apiEndpoint}/users`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return users.data;
  } catch (err) {
    throw new Error(err);
  }
};

const setNewPassword = async (data) => {
  try {
    const users = await axios({
      method: 'PUT',
      url: `${config.apiEndpoint}/users/password`,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return users.data;
  } catch (err) {
    throw new Error(err);
  }
};

const setNeedsPasswordReset = async (user) => {
  try {
    const users = await axios({
      method: 'GET',
      url: `${config.apiEndpoint}/users/password/reset/${user}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return users.data;
  } catch (err) {
    throw new Error(err);
  }
};

const sendRecoveryEmail = async (email) => {
  try {
    const users = await axios({
      method: 'POST',
      url: `${config.apiEndpoint}/users/password/reset`,
      data: { email },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return users.data;
  } catch (err) {
    throw new Error(err);
  }
};

const deleteUser = async (id) => {
  try {
    const users = await axios({
      method: 'DELETE',
      url: `${config.apiEndpoint}/users/${id}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return users.data;
  } catch (err) {
    throw new Error(err);
  }
};

const saveModificationsUser = async (data) => {
  try {
    const users = await axios({
      method: 'PUT',
      url: `${config.apiEndpoint}/users`,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return users.data;
  } catch (err) {
    throw new Error(err);
  }
};

const saveUserInfo = async (data) => {
  try {
    const users = await axios({
      method: 'PATCH',
      url: `${config.apiEndpoint}/users`,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return users.data;
  } catch (err) {
    throw new Error(err);
  }
};

const isValidEmail = (email) => {
  // eslint-disable-next-line
  const re =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export {
  getUsers,
  setNewPassword,
  setNeedsPasswordReset,
  sendRecoveryEmail,
  saveModificationsUser,
  saveUserInfo,
  deleteUser,
  isValidEmail,
};
