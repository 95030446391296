import axios from 'axios';
import config from '../../config/main';
import tableConfig from '../../config/tableConfig';

const getCSV = async (url) => {
    if (url) {
        try {
            const response = await axios({
                method: 'GET',
                url,
            });
            return response.data;
        } catch (err) {
            throw new Error(err);
        }
    }
};

const getCSVPromos = async () => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${config.apiEndpoint}/promotions`,
            withCredentials: true,
        });
        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

const getBusinessConfig = async () => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${config.apiEndpoint}/business/${config.business}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache': 'no-cache'
            },
            withCredentials: true,
        });
        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

const csvToJSON = (data, delimiter = ',') => {
    const titles = data.slice(0, data.indexOf('\n')).split(delimiter);
    return data
        .slice(data.indexOf('\n') + 1)
        .split('\n')
        .map(v => {
            const values = v.split(delimiter);
            // eslint-disable-next-line no-sequences
            return titles.reduce((obj, title, index) => ((obj[title] = values[index]), obj), {});
        });
};

const formatCurrency = (rawNumber) =>{
    const formatter = new Intl.NumberFormat(tableConfig.locales.region, {
        style: 'currency',
        currency: tableConfig.locales.currency,
        minimumFractionDigits: 2
    });
    return formatter.format(rawNumber);
};

const formatDate = (rawDateString) => new Date(rawDateString).toLocaleDateString(tableConfig.locales.region);

const formatData = (rawData, key) => {
    const formatTable = tableConfig.format[key];
    let value = rawData;
    if (formatTable) {
        switch (formatTable) {
            case 'money':
                value = formatCurrency(rawData);
                break;
            default:
                value = rawData;
        }
    }
    return value;
};

export default {
    getBusinessConfig,
    getCSV,
    getCSVPromos,
    csvToJSON,
    formatData,
    formatCurrency,
    formatDate,
}