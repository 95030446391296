import React, { useState } from 'react';
import statusConfig from '../../config/statusFlow';

const initialStatusName = 'Seleccionar';
const initialStatusValue = 'select'

const AdminSearch = (props) => {
  const [currentStatus, setCurrentStatus] = useState(initialStatusName);

  const setFromDate = (e) => {
    const date = e.target.value;
    if (date) {
      props.setDateFrom(new Date(date).toISOString())
    }
  };

  const setToDate = (e) => {
    const date = e.target.value;
    if (date) {
      props.setDateTo(new Date(date).toISOString())
    }
  };

  const setStatus = (e) => {
    const status = e.target.value;
    if (status && status !== initialStatusValue) {
      setCurrentStatus(status);
      props.setStatus(status);
    } else {
      setCurrentStatus(null);
      props.setStatus(null);
    }
  };

  const generateSelectStatus = () => {
    const statusList = statusConfig.flow;
    const statusOptions = [];
    // Add the first status option to select
    statusOptions.push(<option key={initialStatusName} value={initialStatusValue}>{initialStatusName}</option>);

    for (let i = 0; i < statusList.length; i++) {
      statusOptions.push(
        <option key={statusList[i]} value={statusList[i]}>{statusList[i]}</option>
      );
    }
    return statusOptions;
  };

  return (
    <div>
      <label className="ml-3">Fecha de Creacion:</label>
      <div className="input-group mb-3 col-12 col-md-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon3">Fecha Desde:</span>
        </div>
        <input
          type="date"
          className="form-control"
          aria-describedby="basic-addon3"
          onChange={setFromDate}
          onBlur={setFromDate}
        />
      </div>
      <div className="input-group mb-3 col-12 col-md-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon3">Fecha Hasta:</span>
        </div>
        <input
          type="date"
          className="form-control"
          aria-describedby="basic-addon3"
          onChange={setToDate}
          onBlur={setToDate}
        />
      </div>
      <div className="form-group mb-3 col-12 col-md-3">
        <label htmlFor="estado">Estado (*):</label>
        <select id="estado" className="form-control" onChange={setStatus} value={currentStatus}>
          {generateSelectStatus()}
        </select>
      </div>
    </div>
  )
};

export default AdminSearch;