import React, { useState, useEffect } from 'react';
import receiptUtils from '../../utils/receipts';
import receiptLocal from './receipts';

import './receiptAddForm.css';

let errorArray = [];

const ReceiptAddForm = (props) => {
  // Global states
  const [receipts, setReceipts] = useState([]);

  // Form
  const [card, setCard] = useState('');
  const [searchCard, setSearchCard] = useState('');
  const [date, setDate] = useState('');
  const [errorDate, setErrorDate] = useState(null);
  const [ticket, setTicket] = useState('');
  const [errorTicket, setErrorTicket] = useState(null);
  const [authNumber, setAuthNumber] = useState('');
  const [errorAuthNumber, setErrorAuthNumber] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [errorQuantity, setErrorQuantity] = useState(null);
  const [batch, setBatch] = useState('');
  const [errorBatch, setErrorBatch] = useState(null);
  const [amount, setAmount] = useState('');
  const [errorAmount, setErrorAmount] = useState(null);
  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);

  useEffect(() => {
    receiptUtils.getCreditCards().then((res) => {
      setCards(res);
      setFilteredCards(res);
    });
    setReceipts(props.receipts);
  }, [setCards, setFilteredCards, props.receipts]);

  /**
   * Forms Event handlers
   * @param {event} e
   */
  const handleDate = (e) => {
    const value = e.target.value;
    const validation = `La fecha es requerida.\n`;
    if (!value) {
      setDate('');
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorDate(validation);
    } else {
      setDate(value);
      setErrorDate(null);
      clearError(validation);
    }
  };

  const handleTicket = (e) => {
    const value = e.target.value;
    const validation = `El nro. de cupon es requerido.\n`;
    if (!value) {
      setTicket('');
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorTicket(validation);
    } else {
      setTicket(value);
      setErrorTicket(null);
      clearError(validation);
    }
  };

  const handleAuthNumber = (e) => {
    const value = e.target.value;
    const validation = `El nro. de autorizacion es requerido.\n`;
    if (!value) {
      setAuthNumber('');
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorAuthNumber(validation);
    } else {
      setAuthNumber(value);
      setErrorAuthNumber(null);
      clearError(validation);
    }
  };

  const handleQuantity = (e) => {
    const value = e.target.value;
    const validation = `La cantidad de coutas es requerida.\n`;
    if (!value) {
      setQuantity('');
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorQuantity(validation);
    } else {
      setQuantity(parseInt(value));
      setErrorQuantity(null);
      clearError(validation);
    }
  };

  const handleBatch = (e) => {
    const value = e.target.value;
    const validation = `El lote es requerido.\n`;
    if (value) {
      setBatch(value);
      setErrorBatch(null);
      clearError(validation);
    } else {
      setBatch('');
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorBatch(validation);
    }
  };

  const handleAmount = (e) => {
    const value = e.target.value;
    const validation = `El monto es requerido.\n`;
    if (value) {
      setAmount(value);
      setErrorAmount(null);
      clearError(validation);
    } else {
      setAmount('');
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorAmount(validation);
    }
  };

  const clearError = (validation) => {
    errorArray = errorArray.filter((elem) => elem !== validation);
  };

  /**
   * Validates if all the fields are included
   */
  const validateAdd = () => {
    let isValid = true;
    if (errorArray.length > 0) isValid = false;
    if (!quantity) isValid = false;
    if (!batch) isValid = false;
    if (!date) isValid = false;
    if (!card) isValid = false;
    if (!amount) isValid = false;
    if (!authNumber) isValid = false;
    if (!ticket) isValid = false;
    return isValid;
  };

  /**
   * Returns the code for an specific credit card
   * @param {string} card
   * @returns {int} code
   */
  const getCreditCardCode = (card) => {
    for (let i = 0; i < cards.length; i++) {
      if (cards[i]['Tarjeta\r'] === card) {
        return cards[i]['Codigo'];
      }
    }
  };

  /**
   * Adds the product to return with the correct model
   */
  const addReceipt = () => {
    const isValid = validateAdd();
    if (isValid) {
      const receipt = {
        date,
        ticket,
        batch,
        amount,
        authNumber,
        payment: card,
        paymentCode: getCreditCardCode(card), // Translate to code
        installments: quantity,
      };
      receipts.push(receipt);
      setReceipts(receipts);
      // Create a new array to trigger re render :)
      props.setReceipts([...receipts]);

      // Save to the local storage
      receiptLocal.saveReceipts(receipts);

      resetForm();
    }
  };

  /**
   * Resets the form with empty values
   */
  const resetForm = () => {
    setCard('');
    setDate('');
    setTicket('');
    setQuantity('');
    setAuthNumber('');
    setBatch('');
    setAmount('');
  };

  const handleSelectedCard = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const value = e.target.textContent;
    setCard(value);
  };

  /**
   * Sorts the brands by displayName
   * @param {Object} a card
   * @param {Object} b card
   */
  const sortCards = (a, b) => {
    if (a['Tarjeta\r'] < b['Tarjeta\r']) {
      return -1;
    }
    if (a['Tarjeta\r'] > b['Tarjeta\r']) {
      return 1;
    }
    return 0;
  };

  /**
   * Generates the credit card options
   * @returns {Array}
   */
  const generateOptions = (cardOptions) => {
    const options = [];
    const sortedCards = cardOptions.sort(sortCards);
    for (let i = 0; i < sortedCards.length; i++) {
      const card = sortedCards[i];
      if (card.Codigo) {
        options.push(
          <button
            onClick={handleSelectedCard}
            className="dropdown-item"
            key={`key-${card.Codigo}`}
          >
            {card['Tarjeta\r']}
          </button>
        );
      }
    }
    return options;
  };

  /**
   * Receives the event for input when searching a credit card
   * @param {Event} e
   */
  const inputSearchCard = (e) => {
    const value = e.target.value;
    if (value) {
      setSearchCard(value);
      filterCard(value);
    } else {
      setSearchCard('');
    }
  };

  /**
   * Filters the collections of cards with the search value
   * @param {Object} data
   */
  const filterCard = (data) => {
    const filtered = cards.filter((card) => {
      if (card['Tarjeta\r']) {
        return card['Tarjeta\r'].toLowerCase().includes(data.toLowerCase());
      } else {
        return false;
      }
    });
    setFilteredCards(filtered);
  };

  return (
    <div className="products-section">
      <div className="form-group receipt-data">
        <div className="date-picker">
          <label>Fecha Cupon</label>
          <input
            type="date"
            className="form-control"
            placeholder="Fecha"
            value={date}
            onChange={handleDate}
            onBlur={handleDate}
          />
          <p className="error-login">{errorDate}</p>
        </div>
        <div>
          <label>Lote Cupon</label>
          <input
            type="text"
            className="form-control"
            placeholder="Lote Cupon"
            value={batch}
            onChange={handleBatch}
            onBlur={handleBatch}
          />
          <p className="error-login">{errorBatch}</p>
        </div>
        <div>
          <label>Numero Cupon</label>
          <input
            type="text"
            className="form-control"
            placeholder="Numero Cupon"
            value={ticket}
            onChange={handleTicket}
            onBlur={handleTicket}
          />
          <p className="error-login">{errorTicket}</p>
        </div>
        <div>
          <label>Numero Autorizacion</label>
          <input
            type="text"
            className="form-control"
            placeholder="Numero Autorizacion"
            value={authNumber}
            onChange={handleAuthNumber}
            onBlur={handleAuthNumber}
          />
          <p className="error-login">{errorAuthNumber}</p>
        </div>
      </div>
      <div className="form-group card-data">
        <div>
          <label>Tarjeta</label>
          <div className="show">
            <button
              className="btn btn-dark dropdown-toggle"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Tarjeta de Credito/Debito
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <input
                onChange={inputSearchCard}
                type="text"
                className="form-control search-input"
                aria-describedby="search-text"
                placeholder="Buscar Tarjeta"
                value={searchCard}
              />
              {generateOptions(filteredCards)}
            </div>
          </div>
        </div>
        <div>
          <label>Tarjeta Seleccionada</label>
          <input
            type="text"
            className="form-control"
            placeholder="Tarjeta"
            defaultValue={card}
            disabled={true}
          />
        </div>
        <div>
          <label>Cuotas</label>
          <input
            type="number"
            min={1}
            max={36}
            className="form-control"
            placeholder="Cantidad Cuotas"
            value={quantity}
            onChange={handleQuantity}
            onBlur={handleQuantity}
          />
          <p className="error-login">{errorQuantity}</p>
        </div>
        <div>
          <label>Monto</label>
          <input
            type="number"
            className="form-control"
            placeholder="Monto"
            value={amount}
            onChange={handleAmount}
            onBlur={handleAmount}
          />
          <p className="error-login">{errorAmount}</p>
        </div>
      </div>
      <div className="form-group">
        <div className="btn-add-receipts">
          <button
            type="button"
            className="btn btn-dark btn-block"
            onClick={addReceipt}
            disabled={!validateAdd()}
          >
            Agregar Cupon
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReceiptAddForm;
