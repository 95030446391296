import React from 'react';

import './brandSearchImage.css';

import brandImages from '../../config/brandConfigS3';
const S3_GEO_BUCKET = 'https://olleapps-geo.s3.us-west-2.amazonaws.com';

const BrandSearch = (props) => {
  const generateButtons = () => {
    const buttons = [];
    props.brands.forEach((brand) => {
      if (Object.keys(brandImages).includes(brand)) {
        buttons.push(
          <button
            type="button"
            className="btn ml-1 mt-1 btn-brand"
            key={`brand-${brand}`}
            title={brand}
            style={{
              border: 'solid 1px',
              backgroundPosition: 'center',
              backgroundImage: `url(${S3_GEO_BUCKET}/logos/${brandImages[brand]})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
            onClick={() => props.buttonCallback(true, brand)}
          />
        );
      }
    });
    return buttons;
  };

  return (
    <div
      className="btn-toolbar m-2"
      role="toolbar"
      aria-label="Toolbar Search Brands"
    >
      <div className="btn-group-lg me-2" role="group" aria-label="Brands">
        {generateButtons()}
      </div>
    </div>
  );
};

export default BrandSearch;
