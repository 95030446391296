import React from 'react';
import Modal from '../modal/modal';
import utils from '../../utils/product/product-utils';

const ArticlesModal = (props) => {
  const hiddenFields = ['Comprobante_Id'];

  const getTitleFromKey = (key) => {
    switch (key) {
      case ' PrecioUnitario':
        return 'PRECIO LISTA';
      case ' Descuento\r':
        return 'PRECIO DESCUENTO';
      default:
        return key.toUpperCase();
    }
  };

  const getHeaders = () => {
    const keyList = Object.keys(props.articles[0]);
    keyList.push('Descuento');

    const headers = keyList.map((key, index) => {
      if (hiddenFields.includes(key)) return null;
      const title = getTitleFromKey(key);
      return <th key={index}>{title}</th>;
    });
    return headers;
  };

  const getDetails = () => {
    return props.articles.map((article, index) => {

      return (
        <tr key={index}>
          {Object.values(article).map((value, i) => {
            if (i === 0) return null;
            // Price
            if (i === 4) {
              return (
                <td className="text-right" key={i}>
                  {utils.formatCurrency(value)}
                </td>
              );
            }
            // Discount price
            if (i === 5) {
              const rows = [];
              rows.push(
                <td className="text-right" key={i}>
                  {utils.formatCurrency( (1 - (value/100)) * Object.values(article)[i-1])}
                </td>
              );
              rows.push(
                <td className="text-right" key={i+1}>
                  {Number(value).toFixed(2)}%
                </td>
              );
              return rows;
            }

            return <td key={i}>{value}</td>;
          })}
        </tr>
      );
    });
  };

  const invoice = props.articles[0]['Comprobante_Id'];

  return (
    <Modal
      show={props.show}
      setShow={props.setShow}
      title={`Detalle Comprobante - ${invoice}`}
      closeButton={true}
    >
      <>
        <table className="table table-sm table-responsive table-hover table-bordered table-custom">
          <thead className="thead-light">
            <tr>{getHeaders()}</tr>
          </thead>
          <tbody>{props.articles.length && getDetails()}</tbody>
        </table>
      </>
    </Modal>
  );
};

export default ArticlesModal;
