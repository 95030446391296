// Define a version and key to have
// background compatibility on new changes
const getNewsKey = () => {
  const newsVersion = '1';
  const customer = localStorage.getItem('customer');
  const newsKey = `news-${newsVersion}-${customer}`;
  return newsKey;
};

const addReadNews = (readNews) => {
  const newsKey = getNewsKey();
  const news = JSON.parse(localStorage.getItem(newsKey));
  if (news) {
    news.push(readNews);
    localStorage.setItem(newsKey, JSON.stringify(news));
  } else {
    localStorage.setItem(newsKey, JSON.stringify([]));
    const news = JSON.parse(localStorage.getItem(newsKey));
    news.push(readNews);
    localStorage.setItem(newsKey, JSON.stringify(news));
  }
};

const getNewsReadByUser = () => {
  const newsKey = getNewsKey();
  return JSON.parse(localStorage.getItem(newsKey)) || [];
};

export default {
  addReadNews,
  getNewsReadByUser,
};
