import React, { useEffect, useState } from 'react';
import Loader from '../loader/loaderComponent';
import newsUtils from '../../utils/news';
import utils from '../../utils/product/product-utils';
import './newsComponent.css';
import localNews from '../../utils/local-news';

const NewsSection = () => {
  const [news, setNews] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const saveReadNews = (readNews) => {
    readNews.forEach(news => localNews.addReadNews(news._id));
  }

  useEffect(() => {
    async function getNews() {
      const newsEnabled = await newsUtils.getEnabledNews();
      return newsEnabled;
    }
    getNews().then(news => {
      if (news.success && news.data && news.data.length > 0) {
        setNews(news.data);
        saveReadNews(news.data);
        setIsLoaded(true);
      } else {
        setIsLoaded(true);
        if (news.data.length === 0) {
          setError('No hay noticias publicadas en la plataforma. Intente nuevamente mas tarde.');
        } else {
          setError('Ocurrio un problema cargando las noticias. Intente nuevamente mas tarde.');
        }
      }
    })
  }, []);

  // Converts shared link of Drive into a direct link
  const generateValidLinkDrive = (url) => {
    const imageID = url.split('/')[5];
    return `https://drive.google.com/uc?export=view&id=${imageID}`;
  };

  const generateNews = (newsItems) => {
    const newsElements = [];
    for (let i = 0; i < newsItems.length; i++) {
      newsElements.push(
        <div key={`news-${i}`} className="col-12 col-md-3 card-custom-promo card-flex-news">
          <div className="card card-news">
            <img className="card-img-top" src={generateValidLinkDrive(newsItems[i].pictureURL)} alt={newsItems[i].title} />
            <div className="card-body">
              <h5 className="card-title">{newsItems[i].title}</h5>
              <div>
                <span className="badge badge-secondary badge-news">
                  {utils.formatDate(newsItems[i].date)}
                </span>
              </div>
              <p className="card-text card-text-news">{newsItems[i].body}</p>
            </div>
          </div>
        </div>
      );
    }
    return newsElements;
  };

  return (
    <>
      <Loader show={!isLoaded} />
      {error && <>
        <p className="error-cart">{error}</p>
        <a className="btn btn-dark btn-cart" href="/menu" role="button">
          Volver
        </a>
      </>
      }
      <div style={{ display: isLoaded ? 'flex' : 'none' }} className="news-container">
        {generateNews(news)}
      </div>
    </>
  )
};

export default NewsSection;
