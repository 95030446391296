import React, { useEffect, useState } from 'react';
import auth from '../../utils/auth-provider/authProvider';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [user, setUser] = useState({ success: false });
  const [order, setOrder] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [needsLogin, setNeedsLogin] = useState(false);
  const [needsPasswordReset, setNeedsPasswordReset] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await auth.getUserInfo();

        // Redirect to login in failure
        if (!data.success) {
          setNeedsLogin(true);
        } else {
          // Sets the customer id to the localstorage
          const customer = localStorage.getItem('customer');
          if (!customer || customer !== data.user._id) {
            localStorage.setItem('customer', data.user._id);
          }
          setUser(data);
        }

        // If needs to re-generate the password
        if (data.user && data.user.needsPasswordReset) {
          setNeedsPasswordReset(true);
        }

        setLoaded(true);
      } catch (err) {
        console.error(
          `Error trying to fetch user data: ${JSON.stringify(err.message)}`
        );
      }
    }
    fetchData();
  }, []);

  const renderComponent = () => {
    if (!loaded) return null;
    if (needsPasswordReset) return <Redirect to="/reset"></Redirect>;
    if (needsLogin) return <Redirect to="/login"></Redirect>;
    return (
      <Route
        {...rest}
        render={(props) =>
          user.success && user.user.active ? (
            <Component
              {...props}
              isLogIn={user.success}
              user={{ ...user.user }}
              cart={rest.cart}
              news={rest.news}
              setOrder={setOrder}
              order={order}
            />
          ) : null
        }
      />
    );
  };

  return <>{renderComponent()}</>;
};

export default PrivateRoute;
