import React from 'react';

const PageControls = (props) => {
  return (
    <nav aria-label="Pagination" style={{ display: props.isFiltered ? 'none' : 'block' }}>
      <ul className="pagination">
        <li className="page-item">
          <button className="page-link" onClick={props.prevPageHandler}>Prev</button>
        </li>
        <li className="page-item disabled">
          <button className="page-link">{props.currentPage}</button>
        </li>
        <li className="page-item disabled">
          <button className="page-link">...</button>
        </li>
        <li className="page-item disabled">
          <button className="page-link">{props.totalPages}</button>
        </li>
        <li className="page-item">
          <button className="page-link" onClick={props.nextPageHandler}>Next</button>
        </li>
      </ul>
    </nav>);
};

export default PageControls;