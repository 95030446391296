import React, { useEffect, useState } from 'react';
import NavBar from '../../components/navbar/navBarComponent';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import MenuItem from '../../components/menu/menuItem';
import receiptLocal from '../../components/receipt-form/receipts';

const ReceiptsPage = (props) => {
  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'Cupones', link: '/receipts' },
  ];

  const [localReceiptsCount, setLocalReceiptsCount] = useState(0);

  // Load the receipts if they're on local storage
  useEffect(() => {
    const localReceipts = receiptLocal.getReceipts();
    setLocalReceiptsCount(localReceipts.length);
  }, []);

  return (
    <div id="main-news-feed" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page="news-feed-admin" items={breadcrumbItems} />
      <div className="menu-container">
        <MenuItem
          title={
            localReceiptsCount === 0
              ? 'Nueva Carga de Cupones'
              : 'Continuar Carga de Cupones'
          }
          text={localReceiptsCount === 0 ? 'Crear Nueva' : 'Continuar Carga'}
          icon="receipt_long"
          link="/receipts-create"
        />
      </div>
    </div>
  );
};

export default ReceiptsPage;
