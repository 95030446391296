import React from 'react';
import Btn from '../buttons/btn';
import './modal.css';

const Modal = ({ children, title, show, setShow, closeButton = false }) => {
  const closeModalExit = (e) => {
    if (e.target.className === 'modal-background') {
      setShow(false);
    }
  };
  return (
    <>
      {show && (
        <div className="modal-background" onClick={closeModalExit}>
          <div className="modalContainer">
            <Btn
              type="button"
              cssClass="btnClose material-icons outlined"
              name="close"
              fn1={() => setShow(!show)}
            />
            <div className="modal-header">
              <h3>{title}</h3>
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer">
              {closeButton && (
                <Btn
                  type="button"
                  name="Close"
                  cssClass=" btn-close btn btn-dark m2"
                  fn1={() => setShow(!show)}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
