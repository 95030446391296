import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './pages/loginPage/loginPage';
import cart from './utils/cart/cartComponent';
import news from './utils/local-news';

const App = () => {
  return (
    <>
      <LoginPage cart={cart} news={news}></LoginPage>
    </>
  );
};

export default App;
