import React from 'react';
import NavBar from '../../components/navbar/navBarComponent';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import ReceiptForm from '../../components/receipt-form/receiptForm';

const ReceiptsCreatePage = (props) => {
  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'Cupones Tarjetas', link: '/receipts' },
    { name: 'Carga Cupones', link: '/receipts-create' },
  ];

  return (
    <div id="main-return-feed-create" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page="returns-feed-admin-create" items={breadcrumbItems} />
      <ReceiptForm user={props.user} />
    </div>
  );
};

export default ReceiptsCreatePage;
