import React, { useState } from 'react';
import Title from '../title-tile/titleTileComponent';

const DeliveryDetails = (props) => {
  const [error, setError] = useState(null);
  const [disabledInput, setDisabledInput] = useState(true);

  const ADDRESS_OPTIONS = [
    'Registrado',
    'Retira en Centro de Distribucion',
    'Envio Otro Domicilio',
  ];

  const generateOptions = () => {
    const options = [];
    for (let i = 0; i < ADDRESS_OPTIONS.length; i++) {
      options.push(
        <button
          onClick={handleSelectedAddressType}
          className="dropdown-item"
          key={`key-${ADDRESS_OPTIONS[i]}`}
        >
          {ADDRESS_OPTIONS[i]}
        </button>
      );
    }
    return options;
  };

  const setAddress = (e) => {
    const address = e.target.value;
    if (address) {
      props.setDeliveryTo(address);
      setError(null);
      props.setHasErrors({ address: false });
    } else {
      setError('La direccion no puede estar vacia.');
      props.setHasErrors({ address: true });
    }
  };

  const handleSelectedAddressType = (e) => {
    const value = e.target.textContent;
    setError(null);
    props.setHasErrors({ address: false });

    // Case other address - clean input and enabled
    if (value === ADDRESS_OPTIONS[2]) {
      props.setDeliveryTo('');
      setDisabledInput(false);
    }

    // Case registered - user data and disabled
    if (value === ADDRESS_OPTIONS[0]) {
      props.setDeliveryTo(props.user.address);
      setDisabledInput(true);
    }

    // Case retires from business - show string
    if (value === ADDRESS_OPTIONS[1]) {
      props.setDeliveryTo(value);
      setDisabledInput(true);
    }
  };

  return (
    <>
      <Title hide={false} message="Detalle de Envio" alignLeft={true} />
      <p className="instructions-text">Seleccione su domicilio de entrega:</p>
      <div className="input-group mb-3 col-12 col-md-4">
        <div className="dropdown show">
          <button
            className="btn btn-dark dropdown-toggle"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Domicilio de Entrega
          </button>

          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {generateOptions()}
          </div>
        </div>
      </div>
      <div className="input-group mb-3 col-12 col-md-4">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon3">
            Domicilio:
          </span>
        </div>
        <input
          disabled={disabledInput}
          type="text"
          className="form-control"
          aria-describedby="basic-addon3"
          onChange={setAddress}
          value={props.deliverToValue}
          onBlur={setAddress}
        />
      </div>
      <p className="details-error">{error}</p>
    </>
  );
};

export default DeliveryDetails;
