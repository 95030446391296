import axios from 'axios';
import config from '../../config/main';

const getEnabledNews = async () => {
  try {
    const news = await axios({
      method: 'GET',
      url: `${config.apiEndpoint}/news-enabled`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return news.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getAllNews = async () => {
  try {
    const news = await axios({
      method: 'GET',
      url: `${config.apiEndpoint}/news`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return news.data;
  } catch (err) {
    throw new Error(err);
  }
};

const saveNews = async (data) => {
  try {
    const news = await axios({
      method: 'POST',
      url: `${config.apiEndpoint}/news`,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return news.data;
  } catch (err) {
    throw new Error(err);
  }
};

const deleteNews = async (id) => {
  try {
    const news = await axios({
      method: 'DELETE',
      url: `${config.apiEndpoint}/news}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return news.data;
  } catch (err) {
    throw new Error(err);
  }
};

const saveModificationsNews = async (data) => {
  try {
    const news = await axios({
      method: 'PUT',
      url: `${config.apiEndpoint}/news`,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return news.data;
  } catch (err) {
    throw new Error(err);
  }
};

export default {
  getAllNews,
  getEnabledNews,
  saveNews,
  saveModificationsNews,
  deleteNews,
};
