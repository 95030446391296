import React, {useState} from 'react';
import './cartPage.css';
import NavBar from '../../components/navbar';
import Breadcrumb from '../../components/breadcrumb';
import CartTable from '../../components/cart-table';

const CartPage = (props) => {
    const [cartItems, setCartItems] = useState(props.cart.getItems());
    const [error, setError] = useState(null);

    const clearCart = () => {
        const confirmDelete = window.confirm('¿Esta seguro que desea eliminar todos los productos del carrito?');
        if (confirmDelete) {
            props.cart.clearCart();
            setCartItems([]);
        }
    };

    const addOrderDetails = () => {
        if (cartItems.length > 0) {
            props.history.push('/checkout')
        } else {
            setError('No puede continuar con el carrito vacio.')
        }
    };

    const continueBuying = () => {
        props.history.push('/order')
    };

    const onDeleteAction = (id) => {
        props.cart.deleteItem(id);
        setCartItems(props.cart.getItems());
    };

    const breadcrumbItems = [
        { name: 'Menu', link: '/menu' },
        { name: 'Pedidos', link: '/order' },
        { name: 'Carrito', link: '/cart' },
    ];

    return (
        <div id="main-cart" className="container-fluid page">
            <NavBar cart={props.cart} isLogIn={props.isLogIn}/>
            <Breadcrumb page='cart' items={breadcrumbItems}/>
            <h3>Carrito</h3>
            <p className="error-cart">{error}</p>
            <CartTable
                onDelete={onDeleteAction}
                cartItems={cartItems}
                setCartItemsCallback={setCartItems}
            />
            <p className="aclaration-text">(*) Los precios son sin IVA y de lista.</p>
            <div className="container row">
                <button className="btn btn-dark btn-100 col-12 col-md-3" onClick={continueBuying}>
                    <i className="material-icons icon-action icon-cart">store</i>
                    Seguir Comprando
                </button>
                <button className="btn btn-dark btn-100 col-12 col-md-3" onClick={clearCart}>
                    <i className="material-icons icon-action icon-cart">remove_shopping_cart</i>
                    Limpiar Carrito
                </button>
                <button className="btn btn-dark btn-100 col-12 col-md-3" onClick={addOrderDetails}>
                    <i className="material-icons icon-action icon-cart">local_shipping</i>
                    Completar Detalles Envio
                </button>
            </div>
        </div>);
};

export default CartPage;