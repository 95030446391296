import React, { useState } from 'react';
import './checkoutPage.css';
import NavBar from '../../components/navbar';
import Breadcrumb from '../../components/breadcrumb';
import OrderDetails from '../../components/order-details/orderDetailsComponent';
import status from '../../config/statusFlow';
import { saveOrder } from '../../utils/order/orderUtils';

const CheckoutPage = (props) => {
  const [deliveryTo, setDeliveryTo] = useState(props.user.address);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [observations, setObservations] = useState(null);
  const [error, setError] = useState(null);
  const [hasErrors, setHasErrors] = useState({ address: false, date: true });
  const [disabled, setDisabled] = useState(false);

  const checkoutCart = (cart) => {
    if (!hasErrors.date && !hasErrors.address) {
      const orderData = {
        date: new Date().toISOString(),
        total: cart.reduce(
          (sum, item) => sum + parseFloat(item['Subtotal']),
          0
        ),
        deliveryDate,
        deliveryTo,
        observations: observations || 'No hay observaciones',
        status: status.defaultStatus,
        user: { _id: props.user._id },
        tags: [],
        cart,
      };

      // Disable the button to avoid twice send the order
      setDisabled(true);

      // Place the order on the server
      saveOrder(orderData)
        .then((result) => {
          if (result.success && result.data) {
            //Set the order data
            props.setOrder(result.data);

            // Clean the cart and go to success page
            setDisabled(false);
            props.cart.clearCart();
            props.history.push('/success');
          } else {
            setError(
              `Upps...Hubo un problema procesando la orden. ${result.error}`
            );
          }
        })
        .catch((error) => {
          setError(`Error intentando realizar la orden: ${error.message}`);
        })
        .finally(() => {
          setDisabled(false);
        });
    } else {
      setError(
        'Completar todos los campos antes de continuar. Fecha y Domicilio de entrega son obligatorios.'
      );
    }
  };

  const handleSetHasErrors = (state) => {
    if ('address' in state) {
      hasErrors.address = state.address;
    }
    if ('date' in state) {
      hasErrors.date = state.date;
    }
    setHasErrors(hasErrors);
  };

  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'Pedidos', link: '/order' },
    { name: 'Carrito', link: '/cart' },
    { name: 'Checkout', link: '/checkout' },
  ];

  return (
    <div id="main-checkout" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page="checkout" items={breadcrumbItems} />
      <h3>Checkout</h3>
      <OrderDetails
        user={props.user}
        deliverToValue={deliveryTo}
        setDeliveryTo={setDeliveryTo}
        setDeliveryDate={setDeliveryDate}
        setDeliveryComments={setObservations}
        setHasErrors={handleSetHasErrors}
      />
      <button
        className="btn btn-dark btn-complete col-12 col-md-3"
        onClick={() => checkoutCart(props.cart.getItems())}
        disabled={disabled}
      >
        Finalizar Pedido
        <i className="material-icons icon-action icon-check">
          check_circle_outline
        </i>
      </button>
      <p className="error-cart">{error}</p>
    </div>
  );
};

export default CheckoutPage;
