import React from 'react';
import statusConfig from '../../config/statusFlow';

const StatusBadge = (props) => {

    const getBadgeType = () => {
        const status = props.status.toUpperCase();
        const style = props.badgeStyle || statusConfig.flowStyles[status] || 'primary';
        return `badge badge-${style}`;
    };

    const style = !props.inline ? {display: 'block', whiteSpace: 'break-spaces'} : null;

    return (
        <span style={style} className={getBadgeType()}>
            {props.status.toUpperCase()}
        </span>
    )
};

export default StatusBadge;