import React from 'react';
import './historyOrders.css';
import utils from '../../utils/product/product-utils';
import StatusBadge from '../status-badge/statusBadge';

const HistoryOrders = (props) => {

    // This method generates the table with the details.
    const generateDetails = (details, orderNumber) => {

        const generateRowDetails = (detailItems) => {
            const detailItemsList = [];
            for (let i=0; i< detailItems.length; i++) {
                detailItemsList.push(
                    <tr key={detailItems[i].sku}>
                        <td>{detailItems[i].sku}</td>
                        <td>{detailItems[i].product}</td>
                        <td>{detailItems[i].specifications[0].Marca}</td>
                        <td>{detailItems[i].specifications[0].Tamaño}</td>
                        <td>{detailItems[i].quantity}</td>
                        <td>{utils.formatCurrency(detailItems[i].price)}</td>
                        <td>{utils.formatCurrency(parseFloat(detailItems[i].price) * parseFloat(detailItems[i].quantity))}</td>
                    </tr>
                );
            }
            return detailItemsList;
        };

        return (
            <table id="details">
                <thead>
                    <tr key={`details-${orderNumber}`}>
                        <th>ID</th>
                        <th>Producto</th>
                        <th>Marca</th>
                        <th>Tamaño</th>
                        <th>Cantidad</th>
                        <th>Precio</th>
                        <th>Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    {generateRowDetails(details)}
                </tbody>
            </table>
        )
    };

    return (
        <div className="card card-custom-history" key={props.orderData.number}>
            <div className="card-header card-header-history" id="headingOne">
                <h5 className="mb-0">
                    <button className="btn btn-width-100"
                            data-toggle="collapse" data-target={`#collapse-${props.orderData.number}`}
                            aria-expanded="true" aria-controls="collapseOne">
                        <table className="table table-custom-history">
                            <tbody>
                                <tr className="media media-custom">
                                    <td className="media-left media-left-custom">
                                        <h5>{`Orden #${props.orderData.number}`}</h5>
                                        <span>
                                            <StatusBadge status={props.orderData.status}/>
                                        </span>
                                    </td>
                                    <td className="media-body media-body-custom">
                                        <h5 className="media-heading font-yellow text-bold hidden-xs">
                                            {`TOTAL: ${utils.formatCurrency(props.orderData.total)}`}
                                        </h5>
                                        <span><strong>Domicilio Envio: </strong>
                                            {props.orderData.deliveryTo}
                                        </span>
                                        <br/>
                                        <span><strong>Fecha Envio: </strong>
                                            {utils.formatDate(props.orderData.deliveryDate)}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                </h5>
            </div>
            <div id={`collapse-${props.orderData.number}`}
                 className="collapse collapse-custom" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="card-body">
                    {generateDetails(props.orderData.orderDetails, props.orderData.number)}
                    <p className="comments-text"> <strong>Comentarios:</strong> {props.orderData.observations}</p>
                </div>
            </div>
        </div>
    )
};

export default HistoryOrders;