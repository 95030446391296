import React from 'react';
import { Redirect } from 'react-router-dom';
import NavBar from '../../components/navbar';
import Breadcrumb from '../../components/breadcrumb';
import Title from '../../components/title-tile/titleTileComponent';
import SuccessSVG from './sucessSvg';
import utils from '../../utils/product/product-utils';
import './sucessPage.css';

const SuccessPage = (props) => {
  const breadcrumbItems = [{ name: 'Menu', link: '/menu' }];

  const getOrderDetails = () => {
    const order = props.order;
    if (order) {
      return (
        <div>
          <div className="col-12 col-md-4 offset-md-4 princing-item green">
            <div className="pricing-divider ">
              <h3 className="text-light text-center">{`Orden # ${order.number}`}</h3>
              <h4 className="my-0 display-4 text-light font-weight-normal mb-3 text-center">
                {utils.formatCurrency(order.total)}
              </h4>
              <SuccessSVG />
            </div>

            <div className="card-body bg-white mt-0 shadow">
              <ul className="list-unstyled mb-0 position-relative">
                <li className="margin-li">
                  <p className="price-text">
                    (*) Los precios son sin IVA y de lista.
                  </p>
                </li>
                <li className="margin-li">
                  <b>Domicilio Entrega: </b> {order.deliveryTo}
                </li>
                <li className="margin-li">
                  <b>Fecha Entrega: </b>{' '}
                  {new Date(order.deliveryDate).toLocaleDateString('es-AR')}
                </li>
                <li className="margin-li">
                  <b>Comentarios: </b> {order.observations}
                </li>
                <li className="text-center margin-li">
                  <b>Se envio un email con los detalles a </b>
                  <a href={`mailto:${props.user.email}`}>{props.user.email}</a>
                </li>
              </ul>
              <a
                role="button"
                href="/menu"
                className="btn btn-lg btn-block btn-dark"
              >
                Volver al Menu
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      // Reload the page will send you to menu again
      return <Redirect to="/menu"></Redirect>;
    }
  };

  return (
    <div id="main-checkout" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page="success" items={breadcrumbItems} />
      <div className="col-12 col-md-4 offset-md-4">
        <Title
          message="Muchas Gracias por utilizar nuestra plataforma para hacer su pedido!"
          theme="success"
        ></Title>
      </div>
      {getOrderDetails()}
    </div>
  );
};

export default SuccessPage;
