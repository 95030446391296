import React from 'react';

const MenuItem = (props) => {
    return (
        <div className="card card-flex card-item-custom">
            <div className="card-header">
                <b>{props.title}</b>
                <span
                    className="badge badge-danger item-title-badge"
                    style={{ display: props.badge ? 'inline-block': 'none' }}
                >{props.badgeText}</span>
            </div>
            <i className="material-icons icon-big">{props.icon}</i>
            <div className="card-body">
                <a href={props.link} className="btn btn-dark btn-menu-item">{props.altText ? props.altText : props.text}</a>
            </div>
        </div>
    );
};

export default MenuItem;