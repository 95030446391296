import React from 'react';
import config from '../../config/tableConfig';
import productUtils from '../../utils/product/product-utils';
import './cartTableComponent.css';
import Title from "../title-tile/titleTileComponent";
import InputQuantity from './inputQuantityComponent';

const CartTable = (props) => {

    // Deletes one item from the cart
    const deleteItem = (id) => {
        props.onDelete(id);
    };

    const generateTable = (items) => {
        const headers = items.length > 0 ? Object.keys(items[0].product) : null;

        const generateHeaders = (headers) => {
            const tableHeaders = [];
            if (headers) {
                const allHeaders = headers.concat(config.cart.columns);
                for (let i = 0; i < allHeaders.length; i++) {
                    // Insert only unhidden columns
                    if (!config.cart.hidden[allHeaders[i]]) {
                        tableHeaders.push(<th className="text-center" key={`col-header-${i}`} scope="col">{allHeaders[i]}</th>);
                    }
                }
            }
            return tableHeaders;
        };

        const generateRowData = (cartItem) => {
            const data = [];
            const keys = Object.keys(cartItem.product);
            keys.forEach(key => {
                // We create the data if not hidden
                if(!config.cart.hidden[key]) {
                    data.push(
                        <td style={config.style[key] ? {textAlign: config.style[key]} : null}
                            key={`${cartItem.product[key]}`}
                            title={cartItem.product[key]}
                        >
                            {productUtils.formatData(cartItem.product[key], key)}
                        </td>)
                }
            });

            // Addition of columns that are now calculated in the cart
            const calculatedColumns = config.cart.columns;
            calculatedColumns.forEach(column => {
                // Add the columns that are no action
                if (!config.cart.actionColumns.includes(column)) {
                    if (column === config.quantityColumn) {
                        data.push(
                            <td className="text-right"
                                key={`${cartItem.product[config.keyColumn]}-${column}`}>
                                <InputQuantity
                                    initialValue={cartItem[column]}
                                    productId={cartItem.product[config.keyColumn]}
                                    productStock={parseInt(cartItem.product[config.stockColumn])}
                                    setCartItemsCallback={props.setCartItemsCallback}
                                    isPromoItem={!!cartItem[config.promoColumn]}
                                />
                            </td>)
                    } else {
                        data.push(
                            <td style={config.style[column] ? {textAlign: config.style[column]} : null}
                                key={`${cartItem.product[config.keyColumn]}-${column}`}>
                                {productUtils.formatData(cartItem[column], column)}
                            </td>
                        );
                    }
                }
            });

            // Add the action columns (delete for now)
            data.push(
                <td className="row-clickeable text-center"
                    onClick={() => deleteItem(cartItem.product[config.keyColumn])}
                    key={`${cartItem.product[config.keyColumn]}-actions`}>
                    <i className="material-icons icon-action icon-md-18">delete</i>
                </td>);

            return data;
        };

        const generateRows = (cartItems) => {
            const items = [];
            let total = 0;
            if (cartItems.length > 0) {
                for (let i = 0; i < cartItems.length; i++) {
                    total += parseFloat(cartItems[i]['Subtotal']);
                    items.push(
                        <tr key={`row-${i}`}>
                            { generateRowData(cartItems[i]) }
                        </tr>
                    )
                }
                // Add the total row
                items.push(<tr key={`row-total`} className="table-active">
                    <td colSpan="5" className="text-right"><b>Total</b></td>
                    <td className="text-right">
                        <b>{ productUtils.formatData(total, 'Total') } </b>
                    </td>
                </tr>);

                return items;
            }
        };

        const table = (
            <table className="table table-sm table-responsive table-hover table-bordered table-custom">
                <thead className="thead-light">
                <tr>
                    {generateHeaders(headers)}
                </tr>
                </thead>
                <tbody>
                {generateRows(items)}
                </tbody>
            </table>
        );

        // Renders the table only if there are items
        return (<>{ items.length > 0 ? table : null }</>)
    };

    return(
        <>
            {generateTable(props.cartItems)}
            <Title
                hide={props.cartItems.length > 0}
                message='Su carrito esta vacio.'
            />
        </>)
};

export default CartTable;