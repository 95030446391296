import React from 'react';
import NavBar from '../../components/navbar/navBarComponent';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import NewsForm from '../../components/news-form/newsForm';

const NewsFeedCreatePage = (props) => {

  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'News Feed', link: '/news-feed' },
    { name: 'Create', link: '/news-feed-create' },
  ];

  return (
    <div id="main-news-feed-create" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page='news-feed-admin-create' items={breadcrumbItems} />
      <NewsForm />
    </div>);
};

export default NewsFeedCreatePage;
