import React from 'react';
import NavBar from '../../components/navbar';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import NewsSection from '../../components/news/newsComponent';

const NewsPage = (props) => {

  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'Noticias', link: '/news' },
  ];

  return (
    <div id="main-news" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page='news' items={breadcrumbItems} />
      <NewsSection />
    </div>);
};

export default NewsPage;