import React from 'react';
import NavBar from '../../components/navbar';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import UserInfo from '../../components/user-info/userInfo';

const UserInfoPage = (props) => {
  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'Mi Cuenta', link: '/user' },
  ];

  return (
    <div id="user-info" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page="account" items={breadcrumbItems} />
      <h3>{props.user.name}</h3>
      <UserInfo user={props.user}></UserInfo>
    </div>
  );
};

export default UserInfoPage;
