import React from 'react';
import NavBar from '../../components/navbar';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import PromoList from '../../components/promos/promosComponent';

const PromoPage = (props) => {

    const breadcrumbItems = [
        { name: 'Menu', link: '/menu' },
        { name: 'Promociones', link: '/promo' },
    ];

    return (
        <div id="main-promo" className="container-fluid page">
            <NavBar cart={props.cart} isLogIn={props.isLogIn}/>
            <Breadcrumb page='promo' items={breadcrumbItems}/>
            <h3>Promociones y Ofertas</h3>
            <PromoList cart={props.cart} history={props.history}/>
        </div>);
};

export default PromoPage;