import axios from 'axios';
import config from '../../config/main';

const saveOrder = async (data) => {
  try {
    const order = await axios({
      method: 'POST',
      url: `${config.apiEndpoint}/orders`,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return order.data;
  } catch (err) {
    let message = err.message;
    if (err.response && err.response.data && err.response.data.error){
      message = err.response.data.error;
    }
    throw new Error(message);
  }
};

const getHistoryOrders = async (user) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${config.apiEndpoint}/orders/user/${user._id}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getPlatformOrders = async (data) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${config.apiEndpoint}/orders/query`,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

const updateOrderStatusByNumber = async (data) => {
  try {
    const response = await axios({
      method: 'PUT',
      url: `${config.apiEndpoint}/orders`,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

const sendQuestion = async (question) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${config.apiEndpoint}/question`,
      data: { question },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      },
      withCredentials: true,
    });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

export {
  saveOrder,
  getHistoryOrders,
  getPlatformOrders,
  updateOrderStatusByNumber,
  sendQuestion,
};
