import React, { useEffect, useState } from 'react';
import NavBar from '../../components/navbar';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import HistoryOrders from '../../components/history-orders/historyOrders';
import Search from '../../components/search/searchComponent';
import { getHistoryOrders } from '../../utils/order/orderUtils';
import PageControls from '../../components/table-pagination/pageControls';

const PAGE_SIZE = 10;

const OrderHistory = (props) => {
  const [historyOrders, setHistoryOrders] = useState(null);
  const [historyOrdersVisible, setHistoryOrdersVisible] = useState(null);
  const [page, setPageNumber] = useState(1);
  const [isFiltered, setIsFiltered] = useState(null);
  const [searchStr, setSearchStr] = useState(null);

  useEffect(() => {
    async function getUserOrders() {
      const orders = await getHistoryOrders(props.user);
      return orders;
    }
    getUserOrders().then((res) => {
      if (res.success) {
        setHistoryOrders(res.data);
        const firstPageOrders = res.data.slice(0, PAGE_SIZE);
        setHistoryOrdersVisible(firstPageOrders);
      }
    });
  }, [props.user]);

  const generateHistoryOrdersLists = (orders) => {
    const orderList = [];
    if (orders) {
      for (let i = 0; i < orders.length; i++) {
        orderList.push(
          <HistoryOrders key={orders[i].number} orderData={orders[i]} />
        );
      }
    }
    return orderList;
  };

  const navigateNextPage = () => {
    const maxPage = Math.ceil(historyOrders.length / PAGE_SIZE);
    const nextPage = page + 1;
    if (nextPage <= maxPage) {
      const nextVisibleItems = historyOrders.slice(
        page * PAGE_SIZE,
        nextPage * PAGE_SIZE
      );
      setHistoryOrdersVisible(nextVisibleItems);
      setPageNumber(nextPage);
    }
  };

  const navigatePrevPage = () => {
    const prevPage = page - 1;
    if (prevPage >= 1) {
      const previousVisibleItems = historyOrders.slice(
        (prevPage - 1) * PAGE_SIZE,
        prevPage * PAGE_SIZE
      );
      setHistoryOrdersVisible(previousVisibleItems);
      setPageNumber(prevPage);
    }
  };

  const filterOrders = (e) => {
    const filterStr = e.target.value;
    setSearchStr(filterStr);

    if (filterStr.length > 0) {
      const filteredOrders = historyOrders.filter((el) => {
        return (
          el.number.toString().startsWith(filterStr) ||
          new Date(el.deliveryDate).toLocaleDateString().includes(filterStr)
        );
      });
      setHistoryOrdersVisible(filteredOrders);
      setIsFiltered(true);
    } else {
      setHistoryOrdersVisible(historyOrders);
      setIsFiltered(false);
    }
  };

  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'Historico Pedidos', link: '/history' },
  ];

  return (
    <div id="main-history" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page="history" items={breadcrumbItems} />
      <h3>Historial Pedidos</h3>
      <p className="aclaration-text">
        Listado de pedidos realizados a traves de la plataforma.
      </p>
      <Search
        placeholder="Busqueda por ID o Fecha de Envio"
        searchCallback={filterOrders}
        value={searchStr}
      />
      {generateHistoryOrdersLists(historyOrdersVisible)}
      <PageControls
        isFiltered={isFiltered}
        currentPage={page}
        nextPageHandler={navigateNextPage}
        prevPageHandler={navigatePrevPage}
        totalPages={
          historyOrders ? Math.ceil(historyOrders.length / PAGE_SIZE) : 0
        }
      />
    </div>
  );
};

export default OrderHistory;
