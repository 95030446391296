import React, {useState} from 'react';
import Title from '../title-tile/titleTileComponent';

const DeliveryDate = (props) => {
    const [error, setError] = useState(null);
    const DAYS_ORDER = 2;

    const setDate = (e) => {
        const date = e.target.value;
        let minDate = new Date();
        minDate.setDate(minDate.getDate() + (DAYS_ORDER - 1));
        const deliveryDate = new Date(date);

        // Check if weekend show error
        if (deliveryDate.getDay() === 5 || deliveryDate.getDay() === 6){
            setError(`La fecha seleccionada no es un dia habil.`);
            return;
        }

        if (date && minDate < deliveryDate) {
            props.setDeliveryDate(deliveryDate.toISOString());
            setError(null);
            props.setHasErrors({ date: false });
        } else {
            setError(`La fecha tiene que estar seleccionada y ser ${DAYS_ORDER} dias mayor a hoy.`);
            props.setHasErrors({ date: true });
        }
    };

    return (
        <>
            <Title
                hide={false}
                message='Fecha de Entrega'
                alignLeft={true}
            />
            <p className="instructions-text">Seleccione la fecha deseable de entrega (2 dias a partir de hoy):</p>
            <div className="input-group mb-3 col-12 col-md-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon3">Fecha:</span>
                </div>
                <input
                    type="date"
                    className="form-control"
                    aria-describedby="basic-addon3"
                    onChange={setDate}
                    onBlur={setDate}
                />
            </div>
            <p className="details-error">{error}</p>
        </>
    );
};

export default DeliveryDate;