import React, { useEffect, useState } from 'react';

const ReceiptsTable = (props) => {
  const [receipts, setReceipts] = useState([]);

  useEffect(() => {
    setReceipts(props.receipts);
  }, [props.receipts]);

  /**
   * Generates the table with the receipts on it
   * @param {*} receipts
   * @returns
   */
  const generateTable = (receipts) => {
    const headers = receipts ? Object.keys(receipts[0]) : null;
    headers.push('actions');

    const generateHeaders = (headers) => {
      const HEADER_MAPS = {
        date: 'Fecha',
        ticket: 'Nro. Cupon',
        authNumber: 'Nro. Autorizacion',
        batch: 'Lote',
        installments: 'Cuotas',
        amount: 'Monto',
        payment: 'Tarjeta',
        paymentCode: 'Codigo Tarjeta',
        actions: 'Acciones',
      };

      const tableHeaders = [];
      if (headers) {
        for (let i = 0; i < headers.length; i++) {
          tableHeaders.push(
            <th className="text-center" key={`col-he-${i}`} scope="col">
              {HEADER_MAPS[headers[i]]}
            </th>
          );
        }
      }
      return tableHeaders;
    };

    const deleteItem = (index) => {
      props.delete(index);
    };

    const generateRowData = (receipt) => {
      const data = [];
      const keys = Object.keys(receipt);
      keys.push('actions');

      keys.forEach((key, index) => {
        if (key === 'actions') {
          data.push(
            <td
              className="row-clickeable text-center"
              key={`${index}-${receipt[key]}-actions`}
            >
              <i className="material-icons icon-action icon-md-18">delete</i>
            </td>
          );
        } else {
          data.push(<td key={`${index}-${receipt[key]}`}>{receipt[key]}</td>);
        }
      });

      return data;
    };

    const generateRows = (receipts) => {
      const productItems = [];
      if (receipts) {
        for (let i = 0; i < receipts.length; i++) {
          productItems.push(
            <tr
              className="row-clickeable"
              key={`row-${i}`}
              onClick={() => deleteItem(i)}
            >
              {generateRowData(receipts[i])}
            </tr>
          );
        }
      }
      return productItems;
    };

    return (
      <>
        <table
          className="table table-sm table-responsive table-hover table-bordered table-custom"
          style={{ maxWidth: '1140px' }}
        >
          <thead className="thead-light">
            <tr>{generateHeaders(headers)}</tr>
          </thead>
          <tbody>{generateRows(receipts)}</tbody>
        </table>
      </>
    );
  };

  return (
    <div className="receipts-table">
      {receipts.length > 0 && generateTable(receipts)}
    </div>
  );
};

export default ReceiptsTable;
