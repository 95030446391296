export default {
  236: [
    // Christian
    125313, 3501395, 469, 1201818, 1201892, 1201914, 3100966, 1201934, 1202061,
    1202068, 133027, 1201829, 100427, 1201883, 10328, 3000162, 1202109, 1200013,
    1201976, 1202049, 1201846, 1201821, 1202036, 1201841, 1201995, 1100597,
    1201749, 1201876, 1201767, 1201884, 1201930, 1202066, 1201747, 1201950,
    1201983, 170839, 1201870, 1201970, 1201868, 1202028, 1202101, 1200025,
    1201894, 1200021, 1202072, 1203003, 1202102, 1200007, 1202048, 1201729,
    1201960, 1201737, 1201891, 120002, 1236, 1201904, 1202042, 1202067, 1202038,
    1201973, 1201844, 1200016, 1201959, 1202044, 1200028, 1201980, 1201789,
    1201961, 1201912, 147564, 1201993, 1200022, 3000131, 119382, 3100918,
    1201901, 1071, 1385, 133328, 3000371, 1203002, 1202090, 1203006, 1202054,
    1201898, 50007, 114735, 1201843, 122257, 3000116, 1201920, 1201835, 3000014,
    2294, 1202057, 1201966, 1201988, 118523, 1201944, 170448, 111033, 1203001,
    1201942, 1201982, 1202007, 1201922, 159159, 1202050, 3000156, 145381,
    1201906, 1202004, 1201997, 1201730, 1202039, 1401308, 1202113, 1201780, 3,
    1201836, 82, 1201977, 4744, 1202082, 140063, 1202075, 1201792, 1202011,
    1201941, 1200014, 1202084, 1202056, 1201779, 254891, 1203004, 1202034,
    1202025, 1201974, 1201948, 1202103, 1202005, 1202046, 140131, 1201967,
    1202024, 1201899, 1201938, 10299, 1100470, 117515, 1202069, 1201828,
    1202023, 1202013, 1202010, 1201975, 1201956, 195112, 1202035, 1201965,
    1202003, 140992, 1201958, 1201911, 1202009, 1202091, 1201909, 111483,
    1201946, 1201947, 119360, 1201984, 1100152, 110323, 150896, 1201733, 119359,
    156313, 1201913, 1203005, 3000269, 1202043, 3300198, 1202264,
  ],
  187: [
    // Lucas
    1202014, 1201951, 118031, 10109, 1201877, 1201948, 1201869, 1201765,
    1202006, 3000257, 1201866, 1201769, 1201936, 1201992, 1105245, 1201763,
    1201963, 1201911, 1201968, 10761, 1201822, 1201952, 1201850, 1202073, 699,
    10592, 1200023, 3000249, 147335, 1201946, 1202076, 1201940, 1201775,
    1201862, 1201890, 1201919, 1201927, 1200027, 1202080, 1201858, 1202063,
    1202064, 1202065, 1402527, 276, 1201867, 1201958, 1201783, 1202051, 3000260,
    1201962, 1202060, 100427, 1201773, 1201768, 1202045, 1201998, 889, 1201838,
    1201817, 1200002, 1201991, 3494, 1200024, 1200010, 1201849, 1202055,
    3300598, 1201918, 3000268, 3000251, 1201923, 1201943, 1201753, 1202074,
    3000253, 1201808, 1202012, 156801, 1201826, 1100152, 1202000, 1200029,
    1202070, 1051, 152375, 1201929, 1202032, 1200030, 1202047, 1201964, 1201957,
    1201969, 153768, 1201999, 1200015, 1201349, 1201935, 1202106, 118874,
    1201887, 1202041, 1201764, 1201985, 1201997, 160177, 3000232, 170448,
    1200011, 1201987, 3000240, 117551, 195838, 110389, 3000281, 177863, 10636,
    152036, 110252, 1201990, 111483, 3000231, 1201886, 1201976, 161278, 1201978,
    110323, 1202052, 1202001, 3000233, 1202114, 1201795, 119371, 119360,
    3000195, 3000294, 1301924, 118872, 1201833, 150896, 1201970, 1201996,
    100791, 10001, 119359, 117513, 110229, 1200009, 118243, 1201812, 1201830,
    1202015, 3500607, 118875, 1201995, 1201961, 1201950,
  ],
  325: [
    // Raul
    1202024, 1202027, 1200020, 1202021, 1202003, 1202026, 1202023, 1202004,
    1202002, 3000269, 755, 1202069, 1200019, 1202005, 1202039, 1202062, 1200017,
    1202019, 1202031, 1202079, 120207, 170448, 1800712, 1202043, 3000371,
    1202038, 1202054,
  ],
  326: [
    // Ruben
    3500607, 3000261, 3000281, 1201780, 3000271, 1201826, 3000265, 160749,
    10636, 3000266, 1051, 1202029, 1202033, 10062, 1201741, 1200012, 1202015,
    3000270, 1202037, 3000264, 1202097, 170448, 1202030, 1202085, 100746,
    1201970, 1201948,
  ],
  232: [
    // Dario
    1202093, 1202117, 1202143, 1202087, 1202124, 1202123, 1202116, 1202110,
    4187, 1202077, 1202144, 1202094, 1202152, 178019, 1202127, 1202148, 120206,
    1202097, 1202142, 1202132, 1202136, 1202158, 1202091, 153378, 1202134,
    1202129, 195019, 1203001, 1202111, 100165, 1202150, 1202153,
    1202154, 1202155, 1202108, 1202131, 1202130, 1202147, 1202107, 1202146,
    10299, 1202128, 1202125, 3100986, 1202115, 1202161, 1202119, 1202140,
    1202141, 1202157, 1202160, 1202135, 50000, 326, 1202159, 1202120, 1202104,
    1202118, 1202121, 170448, 1202096, 1202156, 1202138, 145381, 254826,
    1202137, 1202139, 1202151,
  ],
};
