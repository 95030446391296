import React from 'react';
import DeliveryDetails from './deliveryDetailsComponent';
import DeliveryDate from './dateDeliveryComponent';
import DeliveryComments from './deliveryCommentsComponent';


const OrderDetails = (props) => {
  return (
    <>
      <DeliveryDetails
        user={props.user}
        deliverToValue={props.deliverToValue}
        setDeliveryTo={props.setDeliveryTo}
        setHasErrors={props.setHasErrors}
      />
      <DeliveryDate
        setDeliveryDate={props.setDeliveryDate}
        setHasErrors={props.setHasErrors}
      />
      <DeliveryComments
        setDeliveryComments={props.setDeliveryComments}
      />
    </>
  )
};

export default OrderDetails;
