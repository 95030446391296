import React, { useState } from 'react';
import NavBar from '../../components/navbar';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import { sendQuestion } from '../../utils/order/orderUtils';

const QuestionPage = (props) => {
  const [question, setQuestion] = useState('');
  const [error, setError] = useState(null);

  const setQuestionText = (e) => {
    const text = e.target.value;
    if (text.length > 0) {
      setQuestion(text);
      setError('');
    } else {
      setError('La consulta no puede estar vacia.');
    }
  };

  const sendQuestionClick = async () => {
    if (question.length > 0) {
      const response = await sendQuestion(question);
      if (response.success) {
        props.history.push('/menu')
      } else {
        setError('Ocurrio un error enviando la consulta. Intente nuevamente.')
      }
    } else {
      setError('La consulta no puede estar vacia.');
    }
  };

  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'Consultas', link: '/questions' },
  ];

  return (
    <div id="main-questions" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page='question' items={breadcrumbItems} />
      <h3>Preguntas y Consultas</h3>
      <p className="aclaration-text">Deseamos eschuchar sus preguntas y consultas.</p>
      <div className="input-group col-12 col-md-4">
        <div className="input-group-prepend">
          <span className="input-group-text">Consulta</span>
        </div>
        <textarea onChange={setQuestionText} onBlur={setQuestionText} className="form-control" aria-label="Ingrese su consulta"></textarea>
      </div>
      <button className="btn btn-dark btn-cart col-12 col-md-4" style={{ marginTop: '15px' }} onClick={sendQuestionClick}>
        <i className="material-icons icon-action icon-cart">contact_support</i>
                Enviar Consulta
            </button>
      <p className="error-cart">{error}</p>
    </div>);
};

export default QuestionPage;
