import React from 'react';

const Breadcrumb = (props) => {
    const generateBreadcrumb = (items) => {
        const breadcrumbItems = [];
        if(items) {
            for (let i = 0; i < items.length; i++) {
                breadcrumbItems.push(<li key={`${props.page}-${items[i].name}`} className="breadcrumb-item">
                    <a href={items[i].link}>{items[i].name}</a>
                </li>);
            }
        }
        return breadcrumbItems;
    };

    return(
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {generateBreadcrumb(props.items)}
            </ol>
        </nav>);
};

export default Breadcrumb;