import React from 'react';
import loader from '../../assets/img/loader.gif';

const Loader = (props) => {
    return (
        <div className="text-center">
            <img style={{ display: props.show ? 'inline-block' : 'none', margin: '25px' }} alt="loading" src={loader}></img>
        </div>
    );
};

export default Loader;