import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = (props) => {

  const onChange = (value) => {
    props.validateCaptcha(!!value);
  }

  return (
    <ReCAPTCHA
      sitekey="6LfFQIMaAAAAAGQGsqh3wd_x_G_bczy2yl8Av3ct"
      onChange={onChange}
    />
  )
}

export default Captcha;
