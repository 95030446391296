import React, { useState } from 'react';
import ProductTable from '../../components/product-table/productTableComponent';
import NavBar from '../../components/navbar';
import Breadcrumb from '../../components/breadcrumb/breadcrumbComponent';
import './orderPage.css';

const Order = (props) => {
  const [cartCount, setCartCount] = useState(props.cart.getItems().length);

  const breadcrumbItems = [
    { name: 'Menu', link: '/menu' },
    { name: 'Pedidos', link: '/order' },
  ];

  return (
    <div id="main-order" className="container-fluid page">
      <NavBar cart={props.cart} isLogIn={props.isLogIn} />
      <Breadcrumb page="order" items={breadcrumbItems} />
      <h3>Pedidos</h3>
      <p className="subtitle-text">
        Hacer click sobre el producto para agregarlo al carrito. (*) Los precios
        son sin IVA y de lista.
      </p>
      <a className="btn btn-dark btn-cart" href="/cart" role="button">
        <i className="material-icons icon-action icon-cart">shopping_cart</i>
        Ver Carrito
        <span className="badge badge-pill badge-margin badge-danger">
          {cartCount}
        </span>
      </a>
      <ProductTable
        cart={props.cart}
        user={props.user}
        cartCount={setCartCount}
      />
    </div>
  );
};

export default Order;
