import React from 'react';

const Title = (props) => {
  const getStyles = () => {
    let styles = '';
    styles += `alert alert-${props.theme || 'secondary'}`;
    if (!props.alignLeft) {
      styles += ' text-center';
    }
    return styles;
  };

  return (
    <div
      style={{ display: props.hide ? 'none' : 'block', fontSize: '18px' }}
      className={getStyles()}
      role="alert"
    >
      {props.message}
    </div>
  );
};

export default Title;
