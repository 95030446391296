import React, { useState } from 'react';
import './newsForm.css';
import NavBar from '../../components/navbar/navBarComponent';
import newsUtils from '../../utils/news';
import Title from '../../components/title-tile/titleTileComponent';

let errorArray = [];

const NewsForm = () => {
  const [error, setError] = useState(null);
  const [title, setTitle] = useState(null);
  const [errorTitle, setErrorTitle] = useState(null);
  const [date, setDate] = useState(null);
  const [errorDate, setErrorDate] = useState(null);
  const [body, setBody] = useState(null);
  const [errorBody, setErrorBody] = useState(null);
  const [pictureURL, setPictureURL] = useState(null);
  const [errorPictureURL, setErrorPictureURL] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState(false);

  const clearError = (validation) => {
    errorArray = errorArray.filter((elem) => elem !== validation);
  };

  const handleTitle = (e) => {
    const value = e.target.value;
    const validation = `El titulo no puede estar vacio.\n`;

    if (value) {
      setTitle(value);
      setErrorTitle(null);
      clearError(validation);
    } else {
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorTitle(validation);
    }
  };

  const handleDate = (e) => {
    const value = e.target.value;
    const validation = 'La fecha debe ser valida.\n';

    if (value) {
      setDate(value);
      setErrorDate(null);
      clearError(validation);
    } else {
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorDate(validation);
    }
  };

  const handleBody = (e) => {
    const value = e.target.value;
    const validation = 'El cuerpo no puede estar vacio.\n';
    if (value) {
      setBody(value);
      setErrorBody(null);
      clearError(validation);
    } else {
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorBody(validation);
    }
  };

  const handlePictureURL = (e) => {
    const value = e.target.value;
    const validation = 'El link a Google Drive no puede estar vacio.\n';
    if (value) {
      setPictureURL(value);
      setErrorPictureURL(null);
      clearError(validation);
    } else {
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorPictureURL(validation);
    }
  };

  const generateIDfromTitle = (title) => {
    return title.toLowerCase().replace(' ', '-');
  };

  const saveNews = () => {
    // Validate the Google Drive URL
    const imageID = pictureURL.split('/')[5];
    const validation = 'El link a Google Drive no puede estar vacio.\n';
    if (!imageID) {
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorPictureURL(validation);
    }

    // If there are errors show and return
    if (errorArray.length > 0) {
      setError(errorArray);
      return;
    }
    setDisabled(true);
    setError([]);
    const id = generateIDfromTitle(title);

    const newsData = {
      date,
      body,
      title,
      id,
      pictureURL,
      enabled: false, // We disable it by default
    };
    newsUtils
      .saveNews(newsData)
      .then((res) => {
        if (res.success) {
          setSuccess(true);
        }
      })
      .catch((err) => {
        console.error(err.message);
        setError(['Fallo creando la noticia. Contacte al administrador.']);
      });
  };

  const generateGeneralError = (errorList) => {
    const errorRender = [];
    if (errorList) {
      for (let i = 0; i < errorList.length; i++) {
        errorRender.push(
          <li key={i} className="error-signup-general">
            {errorList[i]}
          </li>
        );
      }
    }
    return errorRender;
  };

  return (
    <div>
      <NavBar />
      <div className="auth-wrapper bg-light">
        <div className="auth-inner form-news">
          <form>
            <div className="form-group">
              <label>Titulo</label>
              <input
                type="text"
                className="form-control"
                placeholder="Titulo"
                onChange={handleTitle}
                onBlur={handleTitle}
                disabled={disabled}
              />
              <p className="error-login">{errorTitle}</p>
            </div>
            <div className="form-group">
              <label>Link Foto Google Drive</label>
              <input
                type="text"
                className="form-control"
                placeholder="Foto Google Drive"
                onChange={handlePictureURL}
                onBlur={handlePictureURL}
                disabled={disabled}
              />
              <p className="error-login">{errorPictureURL}</p>
            </div>
            <div className="form-group">
              <label>Fecha</label>
              <input
                type="date"
                className="form-control"
                placeholder="Fehcha"
                onChange={handleDate}
                onBlur={handleDate}
                disabled={disabled}
              />
              <p className="error-login">{errorDate}</p>
            </div>
            <div className="form-group">
              <label>Cuerpo</label>
              <textarea
                type="text"
                className="form-control"
                placeholder="Cuerpo"
                onChange={handleBody}
                onBlur={handleBody}
                disabled={disabled}
              />
              <p className="error-login">{errorBody}</p>
            </div>
            <button
              type="button"
              className="btn btn-dark btn-block"
              onClick={saveNews}
              disabled={disabled}
            >
              Crear Noticia
            </button>
            <ul className="error-list-general">
              {generateGeneralError(error)}
            </ul>
          </form>
          <div
            className="col-12 success-signup"
            style={{ display: success ? 'block' : 'none' }}
          >
            <Title
              message="La noticia se creo correctamente!"
              theme="success"
            />
            <a
              className="btn btn-dark btn-cart"
              href="/news-feed"
              role="button"
            >
              Volver
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsForm;
