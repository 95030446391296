import React from 'react';
import { Link } from 'react-router-dom';
import auth from '../../utils/auth-provider/authProvider';
import './navBarComponent.css';

import defaultLogoCube from '../../assets/img/logo-kum-192.png';

const NavBar = (props) => {
  const getNavbarItems = () => {
    const navItems = [];

    if (props.isLogIn) {
      navItems.push(
        <li className="nav-item" key="cart">
          <Link className="nav-link" to={'/cart'}>
            <i className="material-icons">shopping_cart</i>
            <span className="badge badge-danger item-title-badge badge-cart">
              {props.cart.getItems().length}
            </span>
          </Link>
        </li>
      );
      navItems.push(
        <li className="nav-item" key="user">
          <Link className="nav-link" to={'/user'}>
            <i className="material-icons">account_circle</i>
          </Link>
        </li>
      );
      navItems.push(
        <li className="nav-item" key="logout">
          <Link className="nav-link" to={'/login'} onClick={auth.logout}>
            <i className="material-icons">logout</i>
          </Link>
        </li>
      );
    } else {
      navItems.push(
        <li className="nav-item" key="register">
          <Link className="nav-link" to={'/register'}>
            Registrarse
          </Link>
        </li>
      );
    }
    return navItems;
  };

  const logoNavigation = () => {
    let route = '/login';
    if (props.isLogIn) {
      route = '/menu';
    }
    return route;
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
      <Link className="navbar-brand" to={logoNavigation}>
        <img
          src={defaultLogoCube}
          width="30"
          height="30"
          alt="Logo"
          style={{ margin: '5px' }}
        ></img>
        Pedidos KUM
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
        <div className="container">
          <ul className="navbar-nav ml-auto fixed-right">{getNavbarItems()}</ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
