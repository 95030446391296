import axios from 'axios';

const S3_GEO_BUCKET = 'https://olleapps-geo.s3.us-west-2.amazonaws.com';

const getProvincias = async () => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${S3_GEO_BUCKET}/provincias.json`,
    });
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

const MAP_NAMES_HANDLES = {
  'Buenos Aires': 'buenosaires.json',
  Catamarca: 'catamarca.json',
  Chaco: 'chaco.json',
  Chubut: 'chubut.json',
  Córdoba: 'cordoba.json',
  Corrientes: 'corrientes.json',
  'Entre Ríos': 'entrerios.json',
  Formosa: 'formosa.json',
  Jujuy: 'jujuy.json',
  'La Pampa': 'lapampa.json',
  'La Rioja': 'larioja.json',
  Mendoza: 'mendoza.json',
  Misiones: 'misiones.json',
  Neuquén: 'neuquen.json',
  'Río Negro': 'rionegro.json',
  Salta: 'salta.json',
  'San Juan': 'sanjuan.json',
  'San Luis': 'sanluis.json',
  'Santa Cruz': 'santacruz.json',
  'Santa Fe': 'santafe.json',
  'Santiago del Estero': 'santiago.json',
  'Tierra del Fuego, Antártida e Islas del Atlántico Sur':
    'tierradelfuego.json',
  Tucumán: 'tucuman.json',
};

const getProvinceHandle = (provinceName) => {
  return MAP_NAMES_HANDLES[provinceName];
};

const getCities = async (provinceName) => {
  try {
    const provinceHandle = getProvinceHandle(provinceName);
    const response = await axios({
      method: 'GET',
      url: `${S3_GEO_BUCKET}/${provinceHandle}`,
    });
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export default {
  getProvincias,
  getCities,
};
