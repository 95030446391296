import React from 'react';
import './searchDropdownComponent.css';

const SearchDropdown = (props) => {
  const toggleDropdown = () => {
    const dropdown = document.getElementById('searchDropdown');
    dropdown.classList.toggle('show');
    if (dropdown.classList.contains('show')) {
      document.getElementById('searchInput').focus();
    }
  };

  const filterFunction = () => {
    let input, filter, div, txtValue, a, i;
    input = document.getElementById('searchInput');
    filter = input.value.toUpperCase();
    div = document.getElementById('searchDropdown');
    a = div.getElementsByTagName('div');
    for (i = 0; i < a.length; i++) {
      txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = '';
      } else {
        a[i].style.display = 'none';
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // If has button we execute the button action
      if (props.includeButton) {
        props.buttonCallback();
      } else {
        props.searchCallback(e);
      }
    }
  };

  const handleKeyDownSearchBrand = (e) => {
    if (e.key === 'Escape') {
      toggleDropdown();
    }
  };

  const selectValue = (e) => {
    const value = e.target.innerText;
    if (value) {
      props.setSearchValueCallback(value);
      toggleDropdown();
      props.buttonCallback(true, value);
    }
  };

  const generateChildDropdown = (values) => {
    const child = [];
    for (let i = 0; i < values.length; i++) {
      child.push(
        <div key={`brand-${i}-${values[i]}`} onClick={selectValue}>
          {values[i]}
        </div>
      );
    }
    return child;
  };

  return (
    <div className="input-group dropdown m-2" style={{ width: 'auto' }}>
      <div className="input-group-prepend">
        <button
          onClick={toggleDropdown}
          className="btn btn-outline-secondary btn-light btn-search-custom"
          title="Presione ESC para salir"
        >
          {props.text}
          <i className="material-icons icon-action icon-down">expand_more</i>
        </button>
        <div id="searchDropdown" className="dropdown-content">
          <input
            key="input-dropdown"
            type="text"
            placeholder="Buscar"
            id="searchInput"
            onKeyUp={filterFunction}
            onKeyDown={handleKeyDownSearchBrand}
          />
          {generateChildDropdown(props.dropdownValues)}
        </div>
      </div>
      {!props.removeInput && (
        <input
          onChange={props.searchCallback}
          onKeyDown={handleKeyDown}
          type="text"
          className="form-control search-input"
          aria-describedby="search-text"
          placeholder={props.placeholder}
          value={props.value || ''}
        ></input>
      )}
      {props.includeButton && (
        <div className="input-group-append">
          <button
            onClick={props.buttonCallback}
            className="btn btn-outline-secondary"
            type="button"
          >
            Buscar
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;
