import React, { useEffect, useState, useCallback } from 'react';
import defaultLogo from '../../assets/img/default-logo-new.png';
import productUtils from '../../utils/product/product-utils';
import geoUtils from '../../utils/geo/geoUtilsS3';
import Title from '../title-tile/titleTileComponent';
import { saveUserInfo } from '../../utils/users/users';
import { isValidEmail } from '../../utils/users/users';

let errorArray = [];

const UserInfo = (props) => {
  const [logoURL, setLogoURL] = useState(defaultLogo);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(props.user.email);
  const [errorEmail, setErrorEmail] = useState();
  const [fullName, setFullName] = useState(props.user.fullName);
  const [errorName, setErrorName] = useState(null);
  const [taxIdentifier, setTaxIdentifier] = useState(props.user.taxIdentifier);
  const [errorTax, setErrorTax] = useState(null);
  const [address, setAddress] = useState(props.user.address);
  const [errorAddress, setErrorAddress] = useState(null);
  const [province, setProvince] = useState(null);
  const [errorProvince, setErrorProvince] = useState(null);
  const [provincesList, setProvincesList] = useState(null);
  const [city, setCity] = useState(null);
  const [errorCity, setErrorCity] = useState(null);
  const [cityList, setCityList] = useState(null);
  const [phone, setPhone] = useState(props.user.phone);
  const [errorPhone, setErrorPhone] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function getBusinessData() {
      const config = await productUtils.getBusinessConfig();
      return config;
    }
    getBusinessData().then((config) => {
      if (config.success && config.data.logoURL) {
        setLogoURL(config.data.logoURL);
      }
    });
    async function getProvinces() {
      const provinces = await geoUtils.getProvincias();
      return provinces;
    }
    getProvinces().then((data) => {
      if (data.cantidad > 0 && data.provincias) {
        setProvincesList(data.provincias);
      }
    });
  }, []);

  const updateCities = useCallback((province) => {
    async function getCities() {
      const cities = await geoUtils.getCities(province);
      return cities;
    }

    const provinceBA = 'Ciudad Autónoma de Buenos Aires';
    if (province === provinceBA) {
      // Case for buenos aires
      setCityList([{ id: 1, nombre: provinceBA }]);
      return;
    }

    getCities().then((data) => {
      if (data.municipios && data.municipios.length > 0) {
        setCityList(data.municipios);
      }
    });
  }, []);

  const clearError = (validation) => {
    errorArray = errorArray.filter((elem) => elem !== validation);
  };

  const handleEmail = (e) => {
    const value = e.target.value;
    const validation = `El email no puede estar vacio o es incorrecto.\n`;
    const isValid = isValidEmail(value);

    if (value && isValid) {
      setEmail(value);
      setErrorEmail(null);
      clearError(validation);
    } else {
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorEmail(validation);
    }
  };

  const handleFullName = (e) => {
    const value = e.target.value;
    const validation = 'El nombre no puede estar vacio.\n';
    if (value) {
      setFullName(value);
      setErrorName(null);
      clearError(validation);
    } else {
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorName(validation);
    }
  };

  const handleTaxIdentifier = (e) => {
    const value = e.target.value;
    const validation = 'El CUIT no puede estar vacio.\n';
    const validationLength = 'El CUIT no puede ser mayor a 11 digitos.\n';

    if (value) {
      if (value.length <= 11) {
        setTaxIdentifier(value);
        setErrorTax(null);
        clearError(validation);
        clearError(validationLength);
      } else {
        if (!errorArray.includes(validationLength)) {
          errorArray.push(validationLength);
        }
        setErrorTax(validationLength);
      }
    } else {
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorTax(validation);
    }
  };

  const handleAddress = (e) => {
    const value = e.target.value;
    const validation = 'El domicilio no puede estar vacio.\n';

    if (value) {
      setAddress(value);
      setErrorAddress(null);
      clearError(validation);
    } else {
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorAddress(validation);
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value;
    const validation = 'El telefono no puede estar vacio.\n';

    if (value) {
      setPhone(value);
      setErrorPhone(null);
      clearError(validation);
    } else {
      if (!errorArray.includes(validation)) {
        errorArray.push(validation);
      }
      setErrorPhone(validation);
    }
  };

  const handleSelectProvince = (e) => {
    const value = e.target.value;
    const provinceValidation = 'Debe seleccionar una provincia.\n';
    if (value) {
      setProvince(value);
      updateCities(value);
      setErrorProvince(null);
      clearError(provinceValidation);
    } else {
      setErrorProvince(provinceValidation);
      if (!errorArray.includes(provinceValidation)) {
        errorArray.push(provinceValidation);
      }
    }
  };

  const handleSelectCity = (e) => {
    const value = e.target.value;
    const cityValidation = 'Debe seleccionar una localidad.\n';
    if (value) {
      setCity(value);
      setErrorCity(null);
      clearError(cityValidation);
    } else {
      setErrorCity(cityValidation);
      if (!errorArray.includes(cityValidation)) {
        errorArray.push(cityValidation);
      }
    }
  };

  const saveUser = () => {
    // Validate city and province are selected
    if (!province) {
      const provinceValidation = 'Debe seleccionar una provincia.\n';
      setErrorProvince(provinceValidation);
      if (!errorArray.includes(provinceValidation)) {
        errorArray.push(provinceValidation);
      }
    }

    if (!city) {
      const cityValidation = 'Debe seleccionar una localidad.\n';
      setErrorCity(cityValidation);
      if (!errorArray.includes(cityValidation)) {
        errorArray.push(cityValidation);
      }
    }

    // If there are errors show and return
    if (errorArray.length > 0) {
      setError(errorArray);
      return;
    }

    // If everything goes well we save the user
    setDisabled(true);
    setError([]);

    const userData = {
      id: props.user._id,
      email,
      fullName,
      taxIdentifier,
      phone,
      address,
      province,
      city,
    };

    // Patch the user information
    saveUserInfo(userData)
      .then((res) => {
        if (res.success) {
          setSuccess(true);
        }
      })
      .catch((err) => {
        console.error(err.message);
        setError(['Fallo guardando los datos, intente nuevamente.']);
      });
  };

  const generateGeneralError = (errorList) => {
    const errorRender = [];
    if (errorList) {
      for (let i = 0; i < errorList.length; i++) {
        errorRender.push(
          <li key={`error-${i}`} className="error-signup-general">
            {errorList[i]}
          </li>
        );
      }
    }
    return errorRender;
  };

  const generateProvinceSelector = () => {
    const provinces = [
      <option key="key-seleccionar" value="select-province">
        {' '}
        Seleccionar
      </option>,
    ];
    if (provincesList && provincesList.length > 0) {
      for (let i = 0; i < provincesList.length; i++) {
        provinces.push(
          <option
            key={`key-${provincesList[i].id}`}
            value={provincesList[i].nombre}
          >
            {provincesList[i].nombre}
          </option>
        );
      }
    }
    return (
      <select
        id="provincia"
        className="form-control"
        onChange={handleSelectProvince}
        value={province || ''}
        disabled={disabled}
      >
        {provinces}
      </select>
    );
  };

  const generateCitySelector = () => {
    const cities = [
      <option key="key-seleccionar" value="select-city">
        {' '}
        Seleccionar
      </option>,
    ];
    if (cityList && cityList.length > 0) {
      for (let i = 0; i < cityList.length; i++) {
        cities.push(
          <option key={`key-${cityList[i].id}`} value={cityList[i].nombre}>
            {cityList[i].nombre}
          </option>
        );
      }
    }
    return (
      <select
        id="ciudad"
        className="form-control"
        onChange={handleSelectCity}
        value={city || ''}
        disabled={disabled}
      >
        {cities}
      </select>
    );
  };

  return (
    <div>
      <div className="auth-wrapper bg-light">
        <div className="auth-inner user-info">
          <form>
            <img
              className="img-fluid padding-middle"
              src={logoURL}
              alt="Logo"
            ></img>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                defaultValue={email}
                onChange={handleEmail}
                onBlur={handleEmail}
                disabled={disabled}
              />
              <p className="error-login">{errorEmail}</p>
            </div>
            <div className="form-group">
              <label>Nombre Completo</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nombre Completo"
                defaultValue={fullName}
                onChange={handleFullName}
                onBlur={handleFullName}
                disabled={disabled}
              />
              <p className="error-login">{errorName}</p>
            </div>
            <div className="form-group">
              <label>CUIL/CUIT (Sin guiones)</label>
              <input
                type="text"
                className="form-control"
                placeholder="CUIT (Sin guiones)"
                defaultValue={taxIdentifier}
                onChange={handleTaxIdentifier}
                onBlur={handleTaxIdentifier}
                disabled={disabled}
              />
              <p className="error-login">{errorTax}</p>
            </div>
            <div className="form-group">
              <label>Domicilio</label>
              <input
                type="text"
                className="form-control"
                placeholder="Domicilio"
                defaultValue={address}
                onChange={handleAddress}
                onBlur={handleAddress}
                disabled={disabled}
              />
              <p className="error-login">{errorAddress}</p>
            </div>
            <div className="form-group">
              <label>Provincia</label>
              {generateProvinceSelector()}
              <p className="error-login">{errorProvince}</p>
            </div>
            <div className="form-group">
              <label>Localidad</label>
              {generateCitySelector()}
              <p className="error-login">{errorCity}</p>
            </div>
            <div className="form-group">
              <label>Telefono</label>
              <input
                type="text"
                className="form-control"
                placeholder="Telefono"
                defaultValue={phone}
                onChange={handlePhone}
                onBlur={handlePhone}
                disabled={disabled}
              />
              <p className="error-login">{errorPhone}</p>
            </div>
            <button
              type="button"
              className="btn btn-dark btn-block"
              onClick={saveUser}
              disabled={success}
            >
              Guardar Cambios
            </button>
            <ul className="error-list-general">
              {generateGeneralError(error)}
            </ul>
          </form>
          <div
            className="col-12 success-signup"
            style={{ display: success ? 'block' : 'none' }}
          >
            <Title
              message="Los datos se guardaron correctamente!"
              theme="success"
            />
            <a className="btn btn-dark btn-cart" href="/menu" role="button">
              Volver al menu
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
